
import React, {Component} from 'react'
import './NewInvoiceServices.css'
import axios from '../../axios-order';
import searchIcon from '../../assets/images/icons/search.png';
import InView, { useInView }  from 'react-intersection-observer';
import {connect} from 'react-redux';
import { throwStatement } from '@babel/types';

/** 
 * Total Summary Component 
 */
function TotalSummary({summaryData, makePayment}) { 
    const summaryTemplate = (summaryData.length>0) ? summaryData.map(data=>{
        return ( 
            <div className="totalSummary">  
                <div>{data.serviceName}</div>  
                <div>{data.quantity}</div> 
                <div>{data.price * data.quantity}</div>   
            </div> 
        )
    }) : null;
    return (
        <div>
            <h2>Services</h2>
            <div>
               {summaryTemplate}
               <div>
                   {(summaryData.length>0) ? <button onClick={()=>makePayment()}>Make Payment</button> : null}
               </div>
            </div>
        </div>
    )
}

/**
 * 
 * @param {*} param0 
 */

function Services({services, addService}){
    const serviceListView = Object.keys(services).map((category, index)=>{
        return (
            <div key={category+index}>
                <h3>{category}</h3>
                {services[category].map((service, index)=>{
                    // return <div key={service.serviceName+index}>
                    //     <div className="itemName">{service.serviceName}</div>
                    //     <div onClick={()=>addService(category, index)} className="addButton">Add</div>
                    // </div>
                    return (
                    <>
                        <div className="itemNameCost">
                            <div className="itemName">
                                {service.serviceName}
                            </div>
                            <div className="itemCost">
                                {/* &#8377; {service.cost} */}
                            </div>
                        </div>
                        <div className="serviceAddButton">
                            <div onClick={()=>addService(category, index)} className="addButton">Add</div>
                        </div>
                    </>
                    )
                })}
            </div>
        )
    })
    return <div>{serviceListView}</div>
 }

function SearchServicesBar(){
    return(
        <div className="searchInvoice">
            <img src={searchIcon} alt="searchIcon" className="searchInvoiceIcon" />
            <input type="text" placeholder='Search for Consultation, Treatment & Injections'/>
            <span className="removeSearchInput">x</span>
        </div>
    )
}

class NewInvoiceHeader extends Component{
    render(){
        return(
            <div className="newInvoiceHeader">
                <div className="inv-headerContainer">
                    <div className="headerHeading">
                        New Invoice
                    </div>
                    <SearchServicesBar 
                    />
                </div>
            </div>
        );
    }
}

class NewInvoiceServices extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            serviceCategories : [],
            services : {},
            summaryData : []
        }
    }

    componentDidMount(){
        // console.log(this.props);
        // debugger;
        this.getServicesHandler(100576);
    }

    getServiceName(service, category) {
        console.log(service, category);
        let serviceName = null;
        switch(category){
            case 'consultations':{
                console.log(service);
                serviceName = service.consultation_description[0].consultation_name;
                break;
            }
            case 'treatments': {
                // console.log('here2');
                serviceName = service.treatment_details.service_name;
                break;
            }
            case 'vaccinations': {
                // console.log('here3');
                serviceName = service.vaccination_details.vaccination_name;
                break;
            }
            default : break;
        }
        console.log(serviceName);
        // debugger;
        return serviceName
    }

    getServicesHandler(docId){
        const getServices = {"getservices": {"doctor_id": docId}}; //Request Body
        axios.post('/postgetconfiguredconsultation_treatments', getServices)
        .then((response)=>{
            //if resposne is in not in a form of array, log error in console.
            console.log(response);
            const services = response.data.configured_consult_treatments; 
            if(typeof(services)!=='object'){
                throw "Bad Response";
                // return []
            }
            
            Object.keys(services).forEach(category=>{
                services[category] = services[category].map(service=>{
                    service.serviceName = this.getServiceName(service, category);
                    service.category = category;
                    return service
                })
            })
            console.log(services);
            this.setState({
                serviceCategories: Object.keys(services),
                services : services
            })

        },(error)=>{
            //Error instruction goes here...
            throw "Error Response!";
            console.log("Error occored while fetching services.");  
        })
    }

    updateserviceslist(category, index){
        //Find index of updated service in a list of services
        const servicesList = {...this.state.services};
    }

    addServiceHandler(category, index){
        // console.log(category, index);
        // const servicesList = {...this.state.services};
        // if(servicesList[category][index].quantity!==undefined){
        //     servicesList[category][index].quantity += 1;
        //     // servicesList[category][index].totalPrice = servicesList[category][index].quantity * servicesList[category][index].price;
        // }
        // else{
        //     servicesList[category][index].quantity = 1;
        //     // servicesList[category][index].totalPrice = servicesList[category][index].price;
        // }
        // this.updateSummaryHandler(servicesList[category][index]);
        // this.setState({services : servicesList});
        const servicesList = {...this.state.services};
        const serviceToUpdate = servicesList[category][index];
        if(serviceToUpdate.quantity!==undefined){
            serviceToUpdate.quantity += 1;
        }
        else{
            serviceToUpdate.quantity = 1;
        }

        // this.updateSummaryHandler(serviceToUpdate);    
        //Update the service List
        servicesList[category][index] = serviceToUpdate;
        this.setState({services : servicesList});
        console.log(serviceToUpdate);
        this.props.updateInvoiceSummary(serviceToUpdate, category);
    }

    updateSummaryHandler(serviceObject){
        // console.log(serviceObject);
        // const summaryData = [...this.state.summaryData];
        // const filteredData = summaryData.filter((item, index)=>{
        //     console.log(item.serviceName, serviceObject.serviceName);
        //     return item.serviceName===serviceObject.serviceName;
        // })
        // console.log(filteredData);

        // if(filteredData.length>0){
        //     const index = summaryData.indexOf(filteredData[0]);
        //     console.log(index);
        //     summaryData[index] = serviceObject;
        // } else {
        //     summaryData.push(serviceObject);
        // }
        // console.log(summaryData);
        // this.setState({summaryData : summaryData});
    }

    makePaymentHandler() {
        this.props.history.push('/reception/newinvoice/'+ this.props.match.params.patientId);
    }

    handleInView(id, isIntersecting){
        console.log('Handle In view called',id, isIntersecting);
        if(isIntersecting){
            this.setState({
                activeCategoryName: id,
                isListInView: isIntersecting,
            })
        }
    }

    render(){
        console.log(this.props);
        // debugger;
        return( 
            <div className="NewInvoiceContainer"> 
                <NewInvoiceHeader /> 
                <div className="servicesContainer">
                    <div className="serviceCategoriesList">
                        <ul>
                            {this.state.serviceCategories.map(category=><li key={category}>{category}</li>)}
                        </ul>
                    </div>
                    <div className="mainContainer" >
                        <div className="servicesListContainer">
                            <h2>Search Results</h2>
                            <Services services={this.state.services} addService={(category, index)=>this.addServiceHandler(category, index)} />
                        </div>
                    </div>
                    <div>
                        <TotalSummary summaryData={(this.props.invoice.invoiceData) ? this.props.invoice.invoiceData : []} makePayment={()=>this.makePaymentHandler()} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        invoice : state.invoice
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        // addService : (service) => dispatch({type:'ADD_SERVICE', service: data}),
        // updateQuantity : (service) => dispatch({type:'UPDATE_SERVICE', service: data})
        updateInvoiceSummary : (updatedService, category) => dispatch({type:'STORE_INVOICE', updatedService: updatedService})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoiceServices);

