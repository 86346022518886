import React from 'react';
import {connect} from 'react-redux';
import axios from '../../axios-order';

function Header() {
    return <div>Header</div>
}

function Services({services, totalBill, discount, changeDiscount, discountType, reduceService, addService, setDiscountType}) {
    // console.log(services);
    const servicesView = services.map((service, index)=>{
        return <div key={'service'+index} style={{"border" : "1px solid black"}}>
            <div>{service.serviceName}</div>
            <div>{service.totalPrice}</div>
            <div>
                <span onClick={()=>reduceService(service)}>-</span>
                {service.quantity}
                <span onClick={()=>addService(service)}>+</span>
            </div>
        </div>
    })
    return (
    <>
        {(services.length>0) ? <div>
            <div>{servicesView}</div>
            <div>Discount {discount}</div>
            <div>Total Bill {totalBill}</div>
            <input onChange={(event)=>changeDiscount(event)} value={discount} />
            <select value={discountType} onChange={(event)=>setDiscountType(event.target.value)}>
                <option value="inr">Rs</option>
                <option value="per">%</option>
            </select>
        </div> : <div>No Services Found</div>}
    </>
    )
}

function Summary({totalPayment, discount, paid, balance, addAmount, amount, makePayment}) { 
    return <div> 
        <h2>Summary</h2> 
        <div>Billed -{totalPayment}</div> 
        <div>Discount -{discount}</div> 
        <div>Paid -{paid}</div> 
        <div>Balance -{balance}</div> 
        <input onChange={(event)=>addAmount(event.target.value)} value={amount} placeholder="Add Amount" /> 
        <button onClick={()=>makePayment()} disabled={totalPayment===0}>Collect Payment</button> 
    </div> 
}

class NewInvoice extends React.Component {
    state = {
        totalPayment : 0,
        discount : 0,
        totalPaid : 0,
        addPay : 0,
        balance : 0,
        discountType : 'inr',
        discountValue : 0
    }

    componentDidMount(){
        console.log(this.props.invoice);
        // debugger;
        let invoiceData = this.props.invoice.invoiceData;
        if(this.props.invoice.selectedInvoice) {
            // Set invoice Data
            invoiceData = this.setInvoiceDataFromInvoice(this.props.invoice.selectedInvoice);
            console.log(invoiceData);
            // debugger;
        } else {
            if(invoiceData.length===0){
                this.props.history.push('/reception/dashboard/dayview');
                return null;
            }
            invoiceData = this.props.invoice.invoiceData;
        }
        console.log(invoiceData);
        // debugger;
        this.setInititalData(invoiceData, false);
    }

    setInvoiceDataFromInvoice(selectedInvoice){
        const invoiceData = [];
        for(let i=0;i<selectedInvoice.billing.length;i++){
            const bill = {
                serviceName : selectedInvoice.billing[i].service_name,
                invoice_id : selectedInvoice.billing[i].invoice_id,
                category : selectedInvoice.billing[i].service_type+'s',  
                service_id : selectedInvoice.billing[i].service_id,
                price : parseInt(selectedInvoice.billing[i].price),
                quantity : selectedInvoice.billing[i].units,
                totalPrice : parseInt(selectedInvoice.billing[i].price)*selectedInvoice.billing[i].units,
                created_by : selectedInvoice.billing[i].created_by, 
                doctor_id : selectedInvoice.billing[i].doctor_id,
                updatedtm : selectedInvoice.billing[i].updatedtm,
                createdtm : selectedInvoice.billing[i].createdtm
            }
            invoiceData.push(bill);
        }
        this.props.storeInvoiceData(invoiceData);
        return invoiceData
    }

    setInititalData(invoiceData , isUpdated) {
        console.log(invoiceData);
        if(invoiceData.length===0){
            return null;
        }

        // if(){

        // } else {
            
        // }
        //Redirect to dashboard if no service exist
        const totalPayment = invoiceData.reduce((in1, in2)=>{
            console.log(in1.totalPrice, in2.totalPrice);
            return {totalPrice:in1.totalPrice + in2.totalPrice}
        }).totalPrice;
        
        // const totalPaid = (this.props.invoice.selectedInvoice) ? this.props.invoice.selectedInvoice.payment.reduce((inv1, inv2)=>{
        //     return {totalPaid : parseInt(inv1.amount) + parseInt(inv2.amount)}
        // }).totalPaid : 0;
        // console.log(totalPaid);
        const totalPaid = (this.props.invoice.selectedInvoice) ? this.props.invoice.selectedInvoice.paidAmount : 0;
        console.log(totalPaid);
        // debugger;   
        const balance = (this.props.invoice.selectedInvoice && !isUpdated) ? this.props.invoice.selectedInvoice.balance : totalPayment - (this.state.discount + this.state.totalPaid);
        this.setState({totalPayment : totalPayment, balance : balance, totalPaid : totalPaid, addPay : 0});
    }

    changeDiscountHandler(discountValue) {
        let discount = 0;
        //Amount entered if not integer
        if(discountValue && isNaN(discountValue)){
            return null;
        }

        if(discountValue.length>0){
            console.log(this.state.discountType);
            // debugger;
            if(this.state.discountType==='inr')
                discount = parseInt(discountValue);
            else
                discount = parseInt(discountValue)*this.state.totalPayment/100;           
        }
        const balance = this.state.totalPayment - (this.state.totalPaid + discount);
        this.setState({discountValue:discountValue, discount:discount, balance:balance});
    }

    addAmountHandler(amount) {
        let amountAdd = 0;
        if(amount && isNaN(amount)){
            return null;
        }
        if(amount.length>0){
            amountAdd=parseInt(amount);
        }
        const prevPayment = (this.props.invoice.selectedInvoice) ? this.props.invoice.selectedInvoice.paidAmount : 0;
        const balance = this.state.totalPayment - (amountAdd + this.state.discount);
        this.setState({totalPaid: prevPayment + amountAdd, addPay:amountAdd, balance:balance});
    }

    getBillArray(isEdit) {
        // console.log(isEdit);
        // debugger;
        const services = this.props.invoice.invoiceData;
        console.log(services);
        // debugger;
        const billArray = [];
        //Loop
        for(let i=0;i<services.length;i++){
            //get service id
            let id = null;
            if(services[i].category==='consultations') {
                id = 'consultation_id';
            } else if(services[i].category==='vaccinations'){
                id = 'vaccination_id';
            } else {
                id = 'service_id';
            }
            const bill = {
                'service_name' : services[i].serviceName,
                'invoice_id' : (isEdit) ? services[i].invoice_id : null,
                'service_type' : services[i].category.substr(0, services[i].category.length-1),
                'service_id' : (isEdit) ? services[i].service_id : services[i][id],
                'units' : services[i].quantity,
                'price' : services[i].price,
                'tax' : 0,
                'created_by': 'reception',
                'createdtm': (isEdit) ? new Date().toISOString() : services[i].createdtm,
                'updatedtm': this.findupdatedtm(isEdit, services[i]),
                "bill_service_id": (isEdit) ? services[i].service_id: null,
                "doctor_id": "100576",
                "address_id": null,
                "service_actual_price":services[i].price,
                "updated_by": "reception"
            }
            billArray.push(bill);
        }
        // console.log(billArray);
        // debugger;
        return billArray;
    }

    findupdatedtm(isEdit, service){
        let updatedtm =  new Date().toISOString();
        // console.log(this.props.invoice.selectedInvoice.billing);
        if(isEdit){
            const billList = this.props.invoice.selectedInvoice.billing;
            for(let i=0;i<billList.length;i++){
                // console.log(billList[i], servsice);
                if(billList[i].service_id===service.service_id && billList[i].serviceName===service.serviceName && billList[i].units === service.quantity){
                    updatedtm = service.updatedtm;
                    break;
                }
            }
        }
        return updatedtm
    }

    getPaymentArray(prevPayment, invoiceId) {
        // console.log(prevPayment, invoiceId);
        // debugger;
        
        //Check if exist
        let totalPaid = 0;
        if(prevPayment!==undefined && prevPayment.length>0){
            totalPaid = prevPayment.reduce((p1, p2)=>{
                return {totalAmount:parseInt(p1.amount) + parseInt(p2.amount)}
            }).totalAmount;
        }

        // debugger;
        // const services = this.props.invoice.invoiceData;
        const paymentArr = prevPayment;
        if(parseInt(this.state.addPay)>0){
            const payment = {
                'is_service' : true,
                'invoice_id' : invoiceId,
                'is_refund' : false,
                'amount' : this.state.addPay,
                'collected_by' : 'reception',
                'payment_mode' : 'cash',
                'collectdtm' : new Date().toISOString()
            }
            paymentArr.push(payment);
        }
        console.log(paymentArr);
        // debugger;
        return paymentArr
    }

    setInvoiceBody(prevInvoices, selectedInvoice) {
        // console.log(prevInvoices);
        // debugger;
        // debugger;
        //Check editing invoice or creating new invoice...(using invoiceId)
        //Billing
        const billing = this.getBillArray(selectedInvoice && selectedInvoice.invoice_id!==undefined);
        console.log(billing);
        // debugger;
        const prevPayment = (this.props.invoice.selectedInvoice) ? this.props.invoice.selectedInvoice.payment : [];
        const payment = this.getPaymentArray(prevPayment, (this.props.invoice.selectedInvoice) ? this.props.invoice.selectedInvoice.invoice_id : null);
        const invoice = [...prevInvoices];
        
        // console.log('previous invoices', invoice);
        invoice.push({
            invoice_id : null,
            patient_id : this.props.match.params.patientId,
            doctor_id : "101161",
            appointment_id : null,
            bill_number : null,
            bill_createdtm : new Date().toISOString(),
            bill_updatedtm : new Date().toISOString(),
            bill_created_by : null,
            bill_updated_by : "reception",
            bill_address_id : "41241",
            bill_discount : this.state.discountValue,
            bill_discount_type : "inr",
            billing : billing,
            payment : payment
        });
        return invoice;
    }
    
    makePaymentHandler() {
        //Set up request body
        const invoiceBody = this.setInvoiceBody(this.props.invoice.invoices, this.props.invoice.selectedInvoice);
        console.log(invoiceBody);
        // debugger;
        console.log({"invoices":{"invoice":invoiceBody, "total_due":0}});
        axios.post('/post_bulk_invoice', {"invoices":{"invoice":invoiceBody, "total_due":0}})
        .then(response=>{
            alert('Invoice created successfully');
            //redirect to billing page
            console.log(response.data);
            // debugger;
        })
    }

    changeServiceQuantityHandler(type, service) {
        // const serviceData = [...this.props.invoice.invoiceData];
        // const serviceIndexInServicesData = serviceData.indexOf(service);
        // console.log(serviceData, serviceIndexInServicesData);
        if(type==='ADD'){
            // console.log(serviceData[serviceIndexInServicesData]);
            // debugger;
            service.quantity += 1;
        } else {
            // if(serviceData[serviceIndexInServicesData].quantity) //Should not equal to zero
            service.quantity -= 1;
        }
        console.log(service);
        // debugger;
        this.props.updateServicesData(service);
        this.setInititalData(this.props.invoice.invoiceData, true);
    }

    setDiscountTypeHandler(type) {
        console.log(type);
        this.setState({discountValue:0, discount:0, discountType:type})
    }

    render() {
        return (
            <div>
                <div>
                    <Header /> 
                    <div style={{"display":"flex", "justifyContent" : "space-between"}}>
                        <Services 
                            services={this.props.invoice.invoiceData} 
                            totalBill={this.state.totalPayment} 
                            discount={this.state.discountValue} 
                            changeDiscount={(event)=>this.changeDiscountHandler(event.target.value)} 
                            addService = {(service)=>this.changeServiceQuantityHandler('ADD', service)} 
                            reduceService = {(service)=>this.changeServiceQuantityHandler('REDUCE', service)} 
                            discountType={this.state.discountType} 
                            setDiscountType={(type)=>this.setDiscountTypeHandler(type)} 
                        /> 
                        <Summary 
                            totalPayment={this.state.totalPayment} 
                            paid={this.state.totalPaid} 
                            balance={this.state.balance} 
                            discount={this.state.discount} 
                            addAmount={(amount)=>this.addAmountHandler(amount)}
                            amount = {this.state.addPay} 
                            makePayment = {()=>this.makePaymentHandler()}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        invoice : state.invoice
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateServicesData : (data) => dispatch({type:'STORE_INVOICE', updatedService: data}),
        storeInvoiceData : (invoiceData) => dispatch({type:'STORE_INVOICE_DATA', invoiceData: invoiceData})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoice);