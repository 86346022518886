import React from 'react';
import './PatientDetails.css';
import {Dropdown, Modal} from 'react-bootstrap';
import Calendar from 'react-calendar';
import { connect } from 'react-redux';
import {findBloodGroup, cmtofeetinches} from '../../assets/js/calculate_details.js';
import moment from 'moment';
import previousIcon from '../../assets/images/icons/previous.png';
import PaymentCard from '../Payment/Payment';
import {localtimeiso} from '../../assets/js/date_conversions'
import CommingSoonIcon from '../../assets/images/comming_soon.svg'

// function CustomAdd({isActive, onHide}){
//     return (
//         <Modal show={isActive} onHide={()=>onHide()} animation={true} centered>
//             <Modal.Body>
//                 <input type="text" maxLength="7" value={paidAmount} onChange={(event)=>onPaidAmount(event.target.value)} className={paymentStyle.paidAmountInput} placeholder="&#x20B9;"/>
//             </Modal.Body>
//             <Modal.Footer>
//             </Modal.Footer>
//         </Modal>
//     )
// }

function Header({actveView, changeView}){
    return (
        <div className="pd-tabs">
            <div onClick={()=>changeView(0)} className={(actveView===0) ? 'activeDetails':null}>Basic</div>
            <div  onClick={()=>changeView(1)} className={(actveView===1) ? 'activeDetails':null}>Vitals</div>
            <div onClick={()=>changeView(2)} className={(actveView===2) ? 'activeDetails':null}>Payment</div>
        </div>
    )
}

class CustomToggle extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.handleClick = this.handleClick.bind(this);
    }
  
    handleClick(e) {
        e.preventDefault();
        this.props.onClick(e);
      }
    
    render() {
      return (
        <div className = "dropMenu">
            <p className="dropdownLabel" onClick={this.handleClick}>{this.props.children}</p>
        </div>
      );
    }
  }


class CustomMenu extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.handleChange = this.handleChange.bind(this);
  
      this.state = { value: '' };
    }

    componentDidMount(){

    }
  
    handleChange(e) {
     console.log('Dropdown value is '+e.target.value.toLowerCase().trim());
      this.setState({ value: e.target.value.toLowerCase().trim() });
    }

    changeHandler(event){
        // console.log(event);
    }
  
    render() {
        console.log(this.props);
      const {
        children,
        style,
        className,
        'aria-labelledby': labeledBy,
      } = this.props;
      
    //   console.log(this.props.type);
      const { value } = this.state;
      console.log(value);

      return (
        <div style={style} className={className} aria-labelledby={labeledBy}>
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              child =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    }
  }
  
  class DateMenu extends React.Component{
    constructor(props, context) {
      super(props, context);
      this.handleChange = this.handleChange.bind(this);
      this.state = { value: '' };
    }
    
    handleChange(e) {
      this.setState({ value: e.target.value.toLowerCase().trim() });
    }

    changeHandler(event){
        // console.log(event);
    }
  
    render() {
      const {
        children,
        style,
        className,
        'aria-labelledby': labeledBy,
      } = this.props;
      const { value } = this.state;
      
      return (
        <div style={style} className={className + ' dobMenu'} aria-labelledby={labeledBy}>
          {children}
        </div>
      );
    }
  }

class PatientDetails extends React.Component {
    
    state = {
        isDobActive : false,
        heightInCm : true,
        view : 0,
        dropItem : {
            type: '',
            numArrrayLength : 0
        },
        billing : [],
        payment : [],
        isCalenderActive : false,
        payableAmount: 0,
        defaultPayableAmount: 0,
        paidAmount: 0,
        medicalService: null,
        isCustomModal :false,
        modeActive: 'cash',
        paymentIndex: null,
        isSystolicDropdownOpen: false,
        isDiastolicDropdownClose: false
        // updatedPayment: [],
        // updatedBilling: []
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        console.log(this.props);
        // debugger;
        if(this.props.selectedPatient){
            // debugger;
            console.log(this.props.selectedPatient);
            console.log(this.props.selectedPatient);
            if(this.props.selectedPatient.patient_dob){
                this.setState({isDobActive:true});
            }
            const modeActive = (this.props.paymentArray.length>0)?this.props.paymentArray.sort((a, b)=>{
                return new Date(b.updatedtm).getTime() - new Date(a.updatedtm).getTime();
            })[0].payment_mode:'cash';
            const payment=[];
            const billing=[];
            if(this.props.previousPayment>0 || true){
                payment.push({
                    amount:this.props.previousPayment.toString(),
                    payment_mode:(this.props.paymentArray.length>0) ? modeActive : this.state.modeActive,
                    is_service:true,
                    updatedtm:localtimeiso(new Date()),
                    collected_by:"reception",
                    is_refund:"false",
                    collectdtm:localtimeiso(new Date()) 
                })
                billing.push({
                    created_by : "reception",
                    price:this.props.previousPayment.toString(),
                    service_name : "medical service",
                    updated_by:"reception",
                    units:1,
                    updatedtm: localtimeiso(new Date()),
                    service_id:"22012",
                    createdtm:localtimeiso(new Date()),
                    tax :"0.0",
                    service_type:"default"  
                })
                console.log(billing, payment);
                this.setState({paymentIndex: this.props.paymentArray.length,payableAmount:this.props.previousPayment, paidAmount : this.props.previousPayment, defaultPayableAmount:this.props.previousPayment, billing : billing[0], payment: payment[0], medicalService : {name:'Medical Service', amount:this.props.previousPayment}, modeActive:modeActive})
            }

            if(this.props.paymentArray.length===0)
                this.setDefaultPayment(this.props.otherApptinfo.appointment_type, null);
            else
                this.setState({paidAmount : this.props.previousPayment});
            //Set default due amount
            // this.setState({payableAmount:medService[0].amount, paidAmount : medService[0].amount, medicalService : {name: 'medical service', amount : medService[0].amount}}) 
        }
    }

    /**
     * prevApptType is already made appointment's selected appointment type 
     * apptType is selected appointment type
     * @param {*} prevApptType 
     * @param {*} apptType 
     */
    setDefaultPayment(prevApptType, apptType){
        // debugger;
        const defaultApptType = (this.props.appointmentData.rescheduleAppt) ? this.props.appointmentData.rescheduleAppt.appointment_type : null;
        // console.log(defaultApptType);
        // console.log(apptType, this.props.previousPayment);
        // console.log(localStorage.getItem('print_prescription')==='true');
        let previousPayment = 0; 
        for(let i=0;i<this.props.paymentArray.length;i++){
            previousPayment = previousPayment + this.props.paymentArray[i].amount;
        }
        // this.props.paymentArray.forEach((a)=>{
        //     previousPayment = previousPayment + a.amount;
        // });
        console.log(previousPayment);
        console.log(this.props.selectedDoctor);
        // if(localStorage.getItem('print_prescription')==='true' && ((apptType==='Normal' &&  this.props.previousPayment===this.props.selectedDoctor.consulting_fee) || (apptType==='Express' &&  this.props.previousPayment===this.props.selectedDoctor.express_fee))){
        //Since setting the default amount for doctor talwar that's why we are using print prescription condition
        if(localStorage.getItem('print_prescription')==='true'){
            // debugger
            const shouldPaymentUpdate = !defaultApptType || (defaultApptType==='Normal' && this.props.previousPayment === this.props.selectedDoctor.consulting_fee) || (defaultApptType==='Express' && this.props.previousPayment === this.props.selectedDoctor.express_fee); 
            // console.log(shouldPaymentUpdate);
            if(shouldPaymentUpdate){
                if(prevApptType!==apptType || apptType===null){
                    // debugger;
                    if((prevApptType==='Normal' && previousPayment===this.props.selectedDoctor.consulting_fee) || 
                    (prevApptType==='Express' && previousPayment===this.props.selectedDoctor.express_fee) || this.props.paymentArray.length===0){
                        const checkapptType = (apptType===null)  ? prevApptType : apptType;
                        const paidAmount = (checkapptType==='Normal') ? this.props.selectedDoctor.consulting_fee:this.props.selectedDoctor.express_fee;
                        console.log(paidAmount);
                        // debugger
                        this.handlePaidAmount(paidAmount.toString(), paidAmount);
                    }
                }
            }
         }
         console.log(this.props.paymentArray);
    }
    
    selectDobHandler(value){
        // console.log("dob is", value);
        this.setState({isCalenderActive:false})
        this.props.dobSelect(value);
    }

    changeNameHandler(value) {
        let patientDetails = {...this.props.user.patientData};
        patientDetails.patient_fullnm = value;
        this.props.userData(patientDetails);
    }

    switchViewHandler(view){
        this.setState({view:view});
    }

    //   /**
    //  * This method will set the payable and paid amount 
    //  * @param {*} paidAmount 
    //  */
    // setAmounts(paidAmount){
    //     if(paidAmount){
    //         if(paidAmount>0){
    //             this.setState({
    //                 payableAmount: paidAmount,
    //                 paidAmount: paidAmount
    //             })
    //         }
    //     }else{
    //         this.setState({
    //             paidAmount: '',
    //             payableAmount: this.state.defaultPayableAmount
    //         })
    //     }
    // }

    // /**
    //  * This method will set the final payment after deducting dues from paid amount
    //  * @param {*} paidAmount 
    //  */
    // setFinalPayable(paidAmount){
    //     var finalPayableAmount =  parseInt(paidAmount) - parseInt(this.state.defaultPayableAmount);
    //     console.log(finalPayableAmount);
    //     if(finalPayableAmount){
    //         if(finalPayableAmount>0){
    //             this.setState({
    //                 medicalService: finalPayableAmount,
    //             })
    //         }else{
    //             this.setState({
    //                 payableAmount: this.state.defaultPayableAmount,
    //                 medicalService: 0
    //             })
    //         }
    //     }
    // }

    findapayable(paid, payable) {
        return (paid>payable) ? paid : payable; 
    }

    findserviceamount(paid, payable){
        console.log(paid, payable);
        return (paid > payable) ? paid-payable : 0
    }
    
    /**
     * This method will handle the amount paid by the patient
     * @param {*} paidAmount 
     */
    handlePaidAmount(paidAmount, due, paymentMode=undefined){
        console.log('Params for handle paid amount ',paidAmount,due, paymentMode);
        console.log(this.props.paymentArray);
        // debugger;
        const paidAmt = (paidAmount && paidAmount!=='' && paidAmount !== "0x" && paidAmount !== "0X") ? (paidAmount.charAt(0).search(/^[0-9]/)>-1) ? parseInt(paidAmount) : 0 : 0;
        const payableAmount = paidAmt;//this.findapayable(paidAmt, due);
        const serviceAmount = paidAmt;//this.findserviceamount(paidAmt, due);
        const updatedDue = (serviceAmount>0) ? 0 : due-paidAmount;
        const previousPaidValue = this.props.previousPayment;
        const actualPaidValue = this.state.paidAmount;
        const updatedPaidValue = paidAmt;
        
        this.setState({
            paidAmount : paidAmt,
            payableAmount : paidAmt,
            medicalService : {name : "Medical Service", amount : paidAmt},
            updatedDue : updatedDue
        }, ()=>{
            console.log('Paid Amount', paidAmt);
            // debugger;
            const billing = [];
            // console.log(this.props.paymentArray);
            const payment = (this.props.paymentArray.length>0 && this.props.paymentArray[0].amount!==0) ? this.props.paymentArray : []; 
            console.log(payment);
            if(paidAmt>0){
                // debugger
                // console.log(this.state.paymentIndex);
                billing.push({...this.state.billing, price : (paidAmt) ? paidAmt.toString() : "0"});
                //if I need to update or push new object to payment array
                if(previousPaidValue === updatedPaidValue) {
                    //delete the last payment object
                    payment.splice(this.state.paymentIndex, 1);                    
                }    
                else {
                    if(previousPaidValue !== actualPaidValue) {
                        //delete the last payment object
                        payment.splice(this.state.paymentIndex, 1);
                    }
                    const diffAmount = paidAmt-this.props.previousPayment;
                    if(diffAmount !== 0){
                         payment[this.state.paymentIndex] = {...this.state.payment, amount : diffAmount, payment_mode:(paymentMode) ? paymentMode : this.state.modeActive};
                    }
                }
            } 
            else {
                console.log(this.state.paymentIndex);
                //Will work for edit and reschedule appointment
                if(this.props.previousPayment>0){
                    payment[this.state.paymentIndex] = {
                        amount:-1*this.props.previousPayment,
                        payment_mode:(paymentMode) ? paymentMode : this.state.modeActive, 
                        is_service : true, 
                        updatedtm :localtimeiso(new Date()),
                        collected_by :"reception",
                        is_refund:"true",
                        collectdtm:localtimeiso(new Date())
                    };
                    billing.push({
                        created_by : "reception",
                        price:-1,
                        service_name : "medical service",
                        updated_by:"reception",
                        units:1,
                        updatedtm: localtimeiso(new Date()),
                        service_id:"22012",
                        createdtm:localtimeiso(new Date()),
                        tax :"0.0",
                        service_type:"default"  
                    })
                }
            }
            // this.setState({updatedPayment : payment, updatedBilling : billing});
            // console.log(this.state.updatedPayment);
            this.props.updatepayment(paidAmt.toString(), serviceAmount.toString(), updatedDue.toString(), payment, billing)
        })
       
        // this.setAmounts(paidAmount);
        // this.setFinalPayable(paidAmount)
    }

    changePaymentMode(paymentMode){
            // debugger
            //Clicked the payment mode
            this.setState({modeActive : paymentMode}, ()=>{
                console.log(this.props, this.state);
                this.handlePaidAmount(this.state.paidAmount.toString(), this.state.defaultPayableAmount, paymentMode);
            })
    }

    // /**
    //  * This method will add the quick add amounts into payable and paid amount
    //  * @param {*} quickAmount 
    //  */
    // addQuickAmount(quickAmount){
    //     this.setState({
    //         paidAmount: parseInt(this.state.paidAmount) + parseInt(quickAmount)
    //     })   
    //     console.log(this.state.paidAmount);
    //     this.setAmounts(parseInt(this.state.paidAmount) + parseInt(quickAmount));
    //     this.setFinalPayable(parseInt(this.state.paidAmount) + parseInt(quickAmount));
    // }

    render() {
        console.log(this.props.selectedPatient);
        let startvalue = 0;
        let differenceDivisor = 1;
        console.log(this.props.selectedDoctor);
        let doctorData = this.props.selectedDoctor;
        console.log(doctorData);
        // console.log(this.state.dropItem.type);
        console.log(this.props.otherApptinfo);
        const selectedDropType=this.state.dropItem.type;
        switch(selectedDropType){
            case 'highbp' :{
                startvalue = 70;
                differenceDivisor = 1;
                break;
            }
            case 'lowbp' :{
                startvalue = 40;
                differenceDivisor = 1;
                break;
            }
            case 'feetheight' : {
                startvalue = 0;
                differenceDivisor = 1;
                break;

            }
            case 'inchheight' : {
                startvalue = 0;
                differenceDivisor = 1;
                break;

            }
            case 'temprature' :{
                startvalue = 75.0;
                differenceDivisor = 10;
                break;
            }
            case 'cmheight' :{
                startvalue = 45;
                differenceDivisor = 1;
                break;
            }
            case 'cmMmHeight' :{
                startvalue = 45;
                differenceDivisor = 1;
                break;
            }            
            case 'weightKg' :{
                startvalue = 0;
                differenceDivisor = 1;
                break;
            }
            case 'weightGm' :{
                startvalue = 0;
                differenceDivisor = 0.2;
                break;
            }
            case 'pulseMin' : {
                startvalue = 30;
                differenceDivisor=1;
                break;
            }
            case 'respiratory' : {
                startvalue = 10;
                differenceDivisor=1;
                break;
            }
            case 'spotwo' : {
                startvalue = 85;
                differenceDivisor=1;
                break;
            }
            case 'headCircumferenceCm' : {
                startvalue = 20;
                differenceDivisor=1;
                break;
            }
            case 'headCircumferenceMm' : {
                startvalue =0;
                differenceDivisor=1;
                break;
            }
            case 'ssf': {
                startvalue = 2
                differenceDivisor=1;
                break;
            }
            case 'tsf' : {
                startvalue = 2
                differenceDivisor=1;
                break;
            }
            case 'muac' : {
                startvalue = 5
                differenceDivisor=1;
                break;
            }
        }

        const patientData = this.props.selectedPatient;
        console.log(patientData);
        let genderValue = null;
        switch(patientData.sex){
            case 'F': {
                genderValue = 'female';
                break;
            }
            case 'M': {
                genderValue = 'male';
                break;
            }
            case 'O': {
                genderValue = 'others';
                break;
            }
            default:{
                genderValue=null;
                break;
            }
        }

        let appointmentType = "Normal";
        console.log(this.props.otherApptinfo.appointment_type);
        switch(this.props.otherApptinfo.appointment_type){
            case 'Normal': {
                appointmentType = 'Normal';
                break;
            }
            case 'Express': {
                appointmentType = 'Express';
                break;
            }
            default:{
                appointmentType="Normal";
                break;
            }
        }
        console.log(this.state.dropItem.numArrrayLength);
        const numberListDropdown = new Array(this.state.dropItem.numArrrayLength).fill(null).map((val, index)=>{
            const actualValue = startvalue+(index/differenceDivisor); 
            return <Dropdown.Item  key={this.state.dropItem.type+index} onClick={()=>{
                if(selectedDropType=='age'){
                    return this.props.selectFromDropdown(this.state.dropItem.type, actualValue)
                }else{
                    return this.props.selectFromDropdownAppt(this.state.dropItem.type, actualValue)
                }
            }}>{actualValue}</Dropdown.Item>
        })
        const bg = findBloodGroup(this.props.selectedPatient.bloodgroup);
        
        const calendar = (this.state.isCalenderActive) ? <Calendar className="dobCalander" value={(this.props.selectedPatient.patient_dob) ? new Date(this.props.selectedPatient.patient_dob) : ""} onChange={(date)=>this.selectDobHandler(date)} maxDate={new Date()}/> :  null;
        // console.log('Props for patient Details', this.props);
        console.log(doctorData.speciality_category);
        const heightField = (doctorData.speciality_category==='pediatrician' && false)? 
        <>
            <div className="cm-mm-view" >
                <Dropdown onClick={()=>{
                    let itemState = {...this.state.dropItem};
                    itemState.type = 'cmMmHeight';
                    itemState.numArrrayLength=136; //Dropdown range 45 to 180
                    this.setState({dropItem:itemState})}}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        {(this.props.otherApptinfo.heightCm!=null && this.props.otherApptinfo.heightCm>0) ? this.props.otherApptinfo.heightCm + ' cm' : 'cm'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        {numberListDropdown}
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown onClick={()=>{
                    let itemState = {...this.state.dropItem};
                    itemState.type = 'mmHeight';
                    itemState.numArrrayLength=10;
                    this.setState({dropItem:itemState})}}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    {(this.props.otherApptinfo.heightMm!=null) ? this.props.otherApptinfo.heightMm + ' mm' : 'mm'}
                    </Dropdown.Toggle>    
                    <Dropdown.Menu as={CustomMenu}>
                        {numberListDropdown}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>:
        <>
            <div style={{"display":(this.state.heightInCm) ? "block" : "none"}}>
                <Dropdown onClick={()=>{
                    let itemState = {...this.state.dropItem};
                    itemState.type = 'cmheight';
                    itemState.numArrrayLength=194; // 45 to 238
                    this.setState({dropItem:itemState})}}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        {(this.props.otherApptinfo.heightCm) ?  this.props.otherApptinfo.heightCm+' cm' : 'cm'}
                    </Dropdown.Toggle>    
                    <Dropdown.Menu as={CustomMenu}>
                        {numberListDropdown}
                    </Dropdown.Menu>
                </Dropdown>
                <span className="option" onClick={()=>this.setState({heightInCm : !this.state.heightInCm})}>{(this.state.heightInCm)?'Feet':'Cms'}?</span>
            </div>
            <div className="feetinch-view" style={{"display":(this.state.heightInCm) ? "none" : "block"}}>
                <Dropdown onClick={()=>{
                    let itemState = {...this.state.dropItem};
                    itemState.type = 'feetheight';
                    itemState.numArrrayLength=8;
                    this.setState({dropItem:itemState})}}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        {(this.props.otherApptinfo.height!=null) ? this.props.otherApptinfo.heightFeet + ' ft' : 'feet'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        {numberListDropdown}
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown onClick={()=>{
                    let itemState = {...this.state.dropItem};
                    itemState.type = 'inchheight';
                    itemState.numArrrayLength=12;
                    this.setState({dropItem:itemState})}}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    {(this.props.otherApptinfo.height!=null) ? this.props.otherApptinfo.heightInch + ' in' : 'inch'}
                    </Dropdown.Toggle>    
                    <Dropdown.Menu as={CustomMenu}>
                        {numberListDropdown}
                    </Dropdown.Menu>
                </Dropdown>
                <span className="option" onClick={()=>this.setState({heightInCm : !this.state.heightInCm})}>{(this.state.heightInCm)?'Feet':'Cms'}?</span>
            </div>
        </>
        let templateView = null;
        console.log(this.state.modeActive);
        switch(this.state.view){
            case 0 : {
                templateView = (
                    <div className="basicDetails">
                    <div className="editDetailsHeading">
                        basic details
                    </div>
                    <form action="" className="basicDetailsForm">
                            <div className="input-field">
                                <input type="text" id="name" onChange={(event)=>this.props.nameChange(event.target.value)} onBlur={()=>this.props.setFieldTrue('name')} value={(patientData) ? patientData.patient_fullnm : null} maxLength="50" tabIndex="1" required />
                                <label>Full Name<span className="requiredField">*</span></label>
                                {(!this.props.isNameValid) ? <div className="errorInput">Enter a valid name.</div> : null}
                            </div>
                            <div className="input-field">
                                <input type="text" inputMode="numeric" id="phone" onChange={(event)=>this.props.numberChange(event.target.value)} value={(patientData) ? patientData.patient_phone_number: null} maxLength="10" required/>
                                <label>Phone{(!this.props.isNew?'*':null)}</label>
                                {(!this.props.isNumberValid) ? <div className="errorInput">Enter a valid number.</div> : null}
                            </div>
                            <div className="input-field span-input-field age-field">
                <div className="selectLabel">{(!this.state.isDobActive)?'Age':'Date of Birth'}</div>
                                {(!this.state.isDobActive) ? (<Dropdown onClick={()=>{
                                    let itemState = {...this.state.dropItem};
                                    itemState.type = 'age';
                                    itemState.numArrrayLength=120;
                                    this.setState({dropItem:itemState})
                                }}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {(this.props.selectedPatient.patient_age>0) ? (this.props.selectedPatient.patient_age + ' Y') : 'Select Age (in Years)'} 
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu}>
                                       {numberListDropdown}
                                    </Dropdown.Menu>
                                </Dropdown>) : 
                                ( <Dropdown onClick={()=>this.setState({isCalenderActive : true})}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {(patientData.patient_dob) ? new moment(patientData.patient_dob).format("YYYY-MM-DD"): 'Select Dob'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={DateMenu}>
                                        {calendar}
                                    </Dropdown.Menu>
                                </Dropdown> )
                                }
                                {(this.props.isDobUpdate) ? <span className="option" onClick={()=>this.setState({isDobActive : !this.state.isDobActive})} >{(this.state.isDobActive) ? 'Age?' :'Dob?'}</span> : null}
                            </div>
                            <div className="input-field select-input-field gender-field">
                                <div className="selectLabel">Gender</div>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {(this.props.selectedPatient && genderValue) ? genderValue : 'Select Gender'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu}>
                                        <Dropdown.Item key="male" onClick={()=>this.props.selectFromDropdown('gender','M')}>Male</Dropdown.Item>
                                        <Dropdown.Item key="female" onClick={()=>this.props.selectFromDropdown('gender','F')}>Female</Dropdown.Item>
                                        <Dropdown.Item key="others" onClick={()=>this.props.selectFromDropdown('gender','O')}>Others</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="input-field city-field">
                            {/* {(patientData.patient_address && patientData.patient_address.district && patientData.patient_address.district!=='NA') ? patientData.patient_address.district : ''} */}
                                <input type="text" id="city" onChange={(event)=>this.props.cityChange(event.target.value)} onBlur={()=>this.props.setFieldTrue('city')} value={(patientData.patient_address && patientData.patient_address.district && patientData.patient_address.district!=='NA') ? patientData.patient_address.district : ''} tabIndex="2" maxLength="50" required/>
                                <label>City</label>
                                {(!this.props.isCityValid) ? <div className="errorInput">Enter a valid city.</div> : null}
                            </div>
                            <div className="input-field select-input-field">
                                 <div className="selectLabel">Blood Group</div>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {(bg) ? bg:'Select'} 
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu}>
                                        <Dropdown.Item key={'O-'} onClick={()=>this.props.selectFromDropdown('bg', 6)}>O-</Dropdown.Item>
                                        <Dropdown.Item key={'O+'} onClick={()=>this.props.selectFromDropdown('bg', 5)}>O+</Dropdown.Item>
                                        <Dropdown.Item key={'A-'} onClick={()=>this.props.selectFromDropdown('bg', 2)}>A-</Dropdown.Item>
                                        <Dropdown.Item key={'A+'} onClick={()=>this.props.selectFromDropdown('bg', 1)}>A+</Dropdown.Item>
                                        <Dropdown.Item key={'B-'} onClick={()=>this.props.selectFromDropdown('bg', 4)}>B-</Dropdown.Item>
                                        <Dropdown.Item key={'B+'} onClick={()=>this.props.selectFromDropdown('bg', 3)}>B+</Dropdown.Item>
                                        <Dropdown.Item key={'AB-'} onClick={()=>this.props.selectFromDropdown('bg', 8)}>AB-</Dropdown.Item>
                                        <Dropdown.Item key={'AB+'}  onClick={()=>this.props.selectFromDropdown('bg', 7)}>AB+</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="input-field select-input-field">
                                <div className="selectLabel">Purpose of Visit</div>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {(this.props.otherApptinfo.isRevisit)?'Revisit':'General'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu  as={CustomMenu}>
                                        <Dropdown.Item onClick={()=>this.props.selectFromDropdownAppt('purpose',false)}>General</Dropdown.Item>
                                        <Dropdown.Item onClick={()=>this.props.selectFromDropdownAppt('purpose',true)}>Revisit</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="input-field select-input-field">
                                <div className="selectLabel">Type of Appointment</div>
                                <div className="apptTypeButtons">
                                    <div className={(appointmentType==='Normal')?"apptTypeButton active":"apptTypeButton"} onClick={
                                        ()=>{
                                            this.setDefaultPayment(this.props.otherApptinfo.appointment_type, 'Normal');
                                            this.props.selectFromDropdownAppt('appointmentType','Normal')  
                                        }}>
                                        Normal
                                    </div>
                                    <div className={(appointmentType==='Express')?"apptTypeButton expressButton active":"apptTypeButton expressButton"} onClick={()=>{
                                        this.setDefaultPayment(this.props.otherApptinfo.appointment_type, 'Express');
                                        this.props.selectFromDropdownAppt('appointmentType','Express');
                                    }}>
                                        Express
                                    </div>
                                </div>
                                {/* <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {(this.props.selectedPatient && appointmentType) ? appointmentType : 'Select Appointment Type'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu}>
                                        <Dropdown.Item key="normal" onClick={()=>this.props.selectFromDropdownAppt('appointmentType','Normal')}>Normal</Dropdown.Item>
                                        <Dropdown.Item key="express" onClick={()=>this.props.selectFromDropdownAppt('appointmentType','Express')}>Express</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                            </div>
                            
                    </form>
                </div>
                );
                break;
            }
            case 1 : {
                templateView = (
                    <div className="vitalDetails">
                    <div className="editDetailsHeading">
                        vitals details
                    </div>
                    <form action="" className="vitalDetailsForm">
                        <div className="input-field span-input-field height-field">
                            <div className="selectLabel">Height ({(this.state.heightInCm)?'cms':'Feet'})</div>
                            {heightField}
                        </div>
                        <div className="input-field span-input-field weight-field">
                            <div className="selectLabel">Weight</div>
                            <Dropdown onClick={()=>{
                                let itemState = {...this.state.dropItem};
                                itemState.type = 'weightKg';
                                itemState.numArrrayLength=200;
                                this.setState({dropItem:itemState})
                            }}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                {(this.props.otherApptinfo.weightKg!=null  && this.props.otherApptinfo.weightKg>0) ? this.props.otherApptinfo.weightKg + ' kg': 'kg'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu as={CustomMenu}>
                                {numberListDropdown}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown onClick={()=>{
                                let itemState = {...this.state.dropItem};
                                itemState.type = 'weightGm';
                                itemState.numArrrayLength=200;
                                this.setState({dropItem:itemState})
                            }}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                    {(this.props.otherApptinfo.weightGm!=null) ? this.props.otherApptinfo.weightGm + ' gm': 'gm'}
                                </Dropdown.Toggle>    
                                <Dropdown.Menu as={CustomMenu}>
                                    {numberListDropdown}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {
                        (doctorData.speciality_category==='pediatrician' && false)?
                        <>
                            <div className="input-field span-input-field head-circum-field">
                                <div className="selectLabel">Head Circumference</div>
                                <Dropdown onClick={()=>{
                                    let itemState = {...this.state.dropItem};
                                    itemState.type = 'headCircumferenceCm';
                                    itemState.numArrrayLength=36;
                                    this.setState({dropItem:itemState})
                                }}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {(this.props.otherApptinfo.headCircumferenceCm!=null && this.props.otherApptinfo.headCircumferenceCm>0) ? this.props.otherApptinfo.headCircumferenceCm + ' cm': 'cm'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu}>
                                        {numberListDropdown}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown onClick={()=>{
                                    let itemState = {...this.state.dropItem};
                                    itemState.type = 'headCircumferenceMm';
                                    itemState.numArrrayLength=10;
                                    this.setState({dropItem:itemState})
                                }}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {(this.props.otherApptinfo.headCircumferenceMm!=null && this.props.otherApptinfo.headCircumferenceMm>0) ? this.props.otherApptinfo.headCircumferenceMm + ' mm': 'mm'}
                                    </Dropdown.Toggle>    
                                    <Dropdown.Menu as={CustomMenu}>
                                    {numberListDropdown}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="input-field span-input-field">
                                <div className="selectLabel">TSF (mm)</div>
                                <Dropdown onClick={()=>{
                                    let itemState = {...this.state.dropItem};
                                    itemState.type = 'tsf';
                                    itemState.numArrrayLength=19;
                                    this.setState({dropItem:itemState})
                                }}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {(this.props.otherApptinfo.tsf) ? (this.props.otherApptinfo.tsf)+ ' mm' : 'TSF'} 
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu}>
                                    {numberListDropdown}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="input-field span-input-field">
                                <div className="selectLabel">MUAC (cm)</div>
                                <Dropdown onClick={()=>{
                                    let itemState = {...this.state.dropItem};
                                    itemState.type = 'muac';
                                    itemState.numArrrayLength=16;
                                    this.setState({dropItem:itemState})
                                }}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {(this.props.otherApptinfo.muac) ? (this.props.otherApptinfo.muac) + ' cm': 'MUAC (cm)'} 
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu}>
                                    {numberListDropdown}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="input-field span-input-field">
                            <div className="selectLabel">SSF (mm)</div>
                            <Dropdown onClick={()=>{
                                let itemState = {...this.state.dropItem};
                                itemState.type = 'ssf';
                                itemState.numArrrayLength=14;
                                this.setState({dropItem:itemState})
                            }}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                    {(this.props.otherApptinfo.ssf) ? (this.props.otherApptinfo.ssf) + ' mm' : 'SSF (mm)'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu as={CustomMenu}>
                                    {numberListDropdown}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        </>: null
                        }
                        <div className="input-field span-input-field">
                            <div className="selectLabel">Temperature</div>
                            <Dropdown onClick={()=>{
                                let itemState = {...this.state.dropItem};
                                itemState.type = 'temprature';
                                itemState.numArrrayLength=356;
                                this.setState({dropItem:itemState})
                            }}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                    {(this.props.otherApptinfo.temprature) ? (this.props.otherApptinfo.temprature) : 'Temperature'} (&#176;F)
                                </Dropdown.Toggle>
                                <Dropdown.Menu as={CustomMenu}>
                                    {numberListDropdown}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="input-field span-input-field blood-group-field">
                        <div className="selectLabel">BP</div>
                            <Dropdown onClick={()=>{
                                    let itemState = {...this.state.dropItem};
                                    itemState.type = 'highbp';
                                    itemState.numArrrayLength=120;
                                    this.setState({dropItem:itemState})
                                }}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                    {(this.props.otherApptinfo.highbp!=null && this.props.otherApptinfo.highbp>0) ?  this.props.otherApptinfo.highbp: 'Systolic'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu as={CustomMenu}>
                                    {numberListDropdown}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown onClick={()=>{
                                    let itemState = {...this.state.dropItem};
                                    itemState.type = 'lowbp';
                                    itemState.numArrrayLength=60;
                                    this.setState({dropItem:itemState})
                                }}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                    {(this.props.otherApptinfo.lowbp!=null && this.props.otherApptinfo.lowbp>0) ?  this.props.otherApptinfo.lowbp: 'Diastolic'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu as={CustomMenu} >
                                    {numberListDropdown}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="input-field span-input-field">
                        <div className="selectLabel">Pulse (per min)</div>
                                <Dropdown onClick={()=>{
                                    let itemState = {...this.state.dropItem};
                                    itemState.type = 'pulseMin';
                                    itemState.numArrrayLength=121;
                                    this.setState({dropItem:itemState})
                                }}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {(this.props.otherApptinfo.pulse) ? (this.props.otherApptinfo.pulse) : 'Pulse'} (per min)
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu}>
                                       {numberListDropdown}
                                    </Dropdown.Menu>
                                </Dropdown>
                        </div>
                        <div className="input-field span-input-field">
                        <div className="selectLabel">Respiratory (per min)</div>
                                <Dropdown onClick={()=>{
                                    let itemState = {...this.state.dropItem};
                                    itemState.type = 'respiratory';
                                    itemState.numArrrayLength=51;
                                    this.setState({dropItem:itemState})
                                }}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {(this.props.otherApptinfo.respiratory) ? (this.props.otherApptinfo.respiratory) : 'Respiratory'} (per min)
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu}>
                                       {numberListDropdown}
                                    </Dropdown.Menu>
                                </Dropdown>
                        </div>
                        <div className="input-field span-input-field">
                        <div className="selectLabel">SpO2 (%)</div>
                                <Dropdown onClick={()=>{
                                    let itemState = {...this.state.dropItem};
                                    itemState.type = 'spotwo';
                                    itemState.numArrrayLength=16;
                                    this.setState({dropItem:itemState})
                                }}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {(this.props.otherApptinfo.spotwo) ? (this.props.otherApptinfo.spotwo) : 'SpO2 (%)'} 
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu}>
                                       {numberListDropdown}
                                    </Dropdown.Menu>
                                </Dropdown>
                        </div>
                        <div className="input-field span-input-field">
                        <div className="selectLabel">BMI</div>
                            <p>{(this.props.otherApptinfo.bmi && this.props.otherApptinfo.bmi>0)?this.props.otherApptinfo.bmi:'--'}</p>
                        </div>
                    </form>
                </div>
                );
                break;
            }
            case 2: {
                templateView = (localStorage.getItem("payment_visibility")==="true") ? <PaymentCard 
                addedAmount = {0} 
                payableAmount={this.state.payableAmount} 
                paidAmount={this.state.paidAmount} 
                onPaidAmount={(value)=>this.handlePaidAmount(value, this.state.defaultPayableAmount)}
                onAddQuickAmount = {(value)=>{ 
                    const addedValue = value; 
                    const paidAmount = this.state.paidAmount + value;
                    this.handlePaidAmount(paidAmount.toString(), this.state.defaultPayableAmount);
                }} 
                medicalService = {this.state.medicalService}
                isAddAmount={false}
                addcustomamount = {()=>this.setState({isCustomModal:true})}
                modeActive={this.state.modeActive}
                changePaymentMode={(paymentMode)=>{
                    console.log('PAYMENT MODE', paymentMode);
                    this.changePaymentMode(paymentMode);
                }} 
            /> : <div className="commingSoon"><img src={CommingSoonIcon} alt="comming soon" /><div>Payment will be added soon</div></div>;
                break;
            }
            default:{
                templateView = 0;
            }
        }
        return (
            <div>
                <div className = "createApptHead">
                    <img src={previousIcon} alt="Back" onClick={()=>this.props.goBack()}/>
                    Patient Details
                </div>
               <div className="mainContainer">
                   <Header actveView={this.state.view} changeView={(view)=>this.setState({view:view})} />
                   {templateView} 
                </div>
                {/* <CustomAdd isActive={this.state.isCustomModal} onHide={()=>this.setState({isCustomModal:false})} /> */}
            </div>
        )
    }
   
}

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => {
    return {
        userData: (userData) => dispatch({type:'STORE_DOCTORS_DATA', userData : userData})
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails)