import React from 'react';
// import checkmark from '../../assets/images/icons/checkmark.svg';
import previousDateIcon from '../../assets/images/icons/previous.png';
import nextDateIcon from '../../assets/images/icons/next.png';
import './CalenderHead.css';
import moment from 'moment';
import {NavLink} from 'react-router-dom';

function CalenderHead(props){
 
  //console.log('calenderhead props', props);
  //console.log(props.date.toDateString()===new Date().toDateString());
  const disableBack = props.category===0 && (props.date.toDateString()===new Date().toDateString());
  const disableNext = props.category===1 && (props.date.toDateString()===new Date().toDateString());
  // const disableNext =  
  //const prevDate = new Date(props.date.setDate(props.date.getDate()-1));
  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return (
      <div className="calender-header">
        <div className="full-date">
            <span className="date">{new moment(props.date).format('DD')}</span>
            <div className="day-month-year">
                <div className="day">{weekDays[props.date.getDay()]}</div>
                <div className="month-year">{months[props.date.getMonth()]} 2019</div>
            </div>
            <div className="dateChangeMenu">
              <img src = {previousDateIcon} onClick = {() => {
                if(!disableBack){
                  props.changeDate(new Date(props.date.setDate(props.date.getDate()-1)))}
                }
              } alt="previous date"/>
              <img src = {nextDateIcon} onClick = {() => {
                if(!disableNext){
                  props.changeDate(new Date(props.date.setDate(props.date.getDate()+1)))} 
                } 
              } alt="previous date"/>
            </div>
        </div>
        
        <div className="right-content">
            {/* <NavLink to = "/reception/dashboard/dayview" className="timeCategory" activeClassName="activeTime" exact>Day</NavLink>
            <div className = "seperator"></div>
            <NavLink to = "/reception/dashboard/monthview" className="timeCategory" activeClassName="activeTime" exact>Month</NavLink> */}
            {/* <div className = "seperator"></div>
            {/* <NavLink to = "/reception/dashboard/monthview" className="timeCategory" activeClassName="activeTime">Month</NavLink> */}
	     </div> 
    </div>
    );
  }

export default CalenderHead;