import React from 'react';
import AllDocIcon from '../../assets/images/alldoc.png';
import nextIcon from '../../assets/images/icons/next.png';
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import './AllDoctorProfile.css';



 const AllDoctorProfile = (props) => {
    return (
        <AccordionItem uuid="0">
        <AccordionItemHeading>
            <AccordionItemButton>
            {/* <div className="sidebar-title">
                <img src={AllDocIcon} />
                <p>All Doctors</p>

            </div> */}
            <div className="alldoc-dropdown" onClick={() =>props.selectCategory(0)}>
                <div className = "doc-profile" >
                    <div className="profile-image">
                        <img src={AllDocIcon} alt="" />
                    </div>
                    <div className="profile-content">
                        <h3>All Doctors</h3>
                    </div>
                </div>
                <span className="notification-icon">{props.totalAppts}</span>
            </div>
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <div className={(props.selectedCategory===0) ? 'dropdownMenu activeItem' : 'dropdownMenu'} onClick = {() => props.selectCategory(0)}>
                <div className="text">
                    Appointments
                </div>
                <div className="counts">
                    {props.upcommingCount}
                </div>
                <div><img src = {nextIcon} style={{"opacity" : (props.selectedCategory===0) ? "1":"0"}} /></div>
            </div>
            
            <div className={(props.selectedCategory===1) ? 'dropdownMenu activeItem' : 'dropdownMenu'} onClick = {() => props.selectCategory(1)}>
                <div className="text">
                    Patient Seen
                </div>
                <div className="counts">
                    {props.patSeenCount}
                </div>
                <div><img src = {nextIcon} style={{"opacity" : (props.selectedCategory===1) ? "1":"0"}}/></div>
            </div>
            <div className={(props.selectedCategory===2) ? 'dropdownMenu activeItem' : 'dropdownMenu'} onClick = {() => props.selectCategory(2)}>
                <div className="text">
                    Cancelled
                </div>
                <div className="counts red">
                    {props.cancelledCount}
                </div>
                <div><img src = {nextIcon} style={{"opacity" : (props.selectedCategory===2) ? "1":"0"}}/></div>
            </div>      
        </AccordionItemPanel>
    </AccordionItem>
    )
 }



 export default AllDoctorProfile