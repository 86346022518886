import React from 'react';
import './PatientAppointment.css';
import apptCancelIcon from '../../../assets/images/icons/cancle.png';
import dotMenu from '../../../assets/images/icons/dot_menu.png';
import rescheduleIcon from '../../../assets/images/icons/reschedule.png';
import editIcon from '../../../assets/images/icons/edit-blue.svg'
import {Link} from 'react-router-dom';
import editPaymentIcon from '../../../assets/images/icons/edit_payment.svg';
import scanIcon from '../../../assets/images/icons/scanner.svg'
import dropdownIcon from '../../../assets/images/icons/dropdown_icon.svg'
import printerIcon from '../../../assets/images/icons/printer.svg'

import {FormControl, Dropdown} from 'react-bootstrap';
// import { Dropdown } from 'react-bootstrap';
// import {Link} from 'react-router-dom';
class CustomToggle extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.handleClick = this.handleClick.bind(this);
    }
  
    handleClick(e) {
      e.preventDefault();
  
      this.props.onClick(e);
    }
  
    render() {
      return (
        <div>
            <div onClick={this.handleClick}>{this.props.children}</div>
        </div>
      );
    }
  }


class CustomMenu extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.handleChange = this.handleChange.bind(this);
      this.state = { value: '' };
    }
  
    handleChange(e) {
      this.setState({ value: e.target.value.toLowerCase().trim() });
    }

    changeHandler(event){
        // console.log(event);
    }
  
    render() {
      const {
        children,
        style,
        className,
        'aria-labelledby': labeledBy,
      } = this.props;
      // console.log(this.props.type);
      const { value } = this.state;
      return (
        
        <div style={style} className={className} aria-labelledby={labeledBy}>
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              child =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    }
  }

function PatientAppointment(props){
  console.log(localStorage.getItem('scan_visibility')==='true');
  // debugger;
  console.log(localStorage.getItem('print_prescription'));
    console.log('Appointment Props', props.updatedTime);
    console.log('Props for PatientAppointment', props);
    let expressFlag = (props.appointmentType==='Express')?
    <div className="apptTypeFlag">
      <span>Exp</span>
    </div>:null

    return (
        <div className="apptListContainer" style = {{borderLeft : "10px solid " + props.colorCode}}>
            {expressFlag}
            <div className="apptListName">{props.name}</div>
            <div className="apptListGender">{(props.gender) ? props.gender : '-'} / {(props.age) ? props.age + 'Y': '-'} </div>
            {/* <div className="apptListGender">{props.slotNumber}</div> */}
            <div className="apptListTime">{props.apptTime}</div>
            <div className="apptListContact">{(props.phoneNumber) ? props.phoneNumber : '---'}</div>
            {(localStorage.getItem('payment_visibility')) ? <div className="apptListAmount">{(localStorage.getItem('payment_visibility')==="true") ? (props.category!==2) ? props.amountPaid : '--' : null}</div> : <div></div>}
            {(props.category==0) ? (<div className="actionButtons">
                {/* <span className="editApptBtn" onClick={()=> props.reschedule('edit')}>Edit</span> */}
                {(localStorage.getItem('scan_visibility')==='true') ? <img src={scanIcon} onClick={()=>props.scan()} className="scanIconImage"/> : null}
                <img src={editIcon} alt="edit appointment" onClick={()=> props.reschedule('edit')} className="editIconImage" title="Edit Appointment" />
                <img src={rescheduleIcon} onClick={() => props.reschedule('reschedule')} className="timeIconImage" title="Reschedule appointment" alt="reschedule Appointment"/>
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    <img src={apptCancelIcon} className="cancelIconImage" alt="Cancel Appointment" title="Cancel Appointment" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu as={CustomMenu}>
                      <Dropdown.Item key="cancelDoc" onClick={()=>props.cancelAppointment('cancel_by_doctor')}>Cancelled by Doctor</Dropdown.Item>
                      <Dropdown.Item key="cancelPat" onClick={()=>props.cancelAppointment('cancel_by_patient')}>Cancelled by Patient</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                  {/* <img src={editPaymentIcon} class="edit_payment_icon" onClick={()=>props.updatepayment()} /> */}
                {(localStorage.getItem('print_prescription')==='true') ? <Dropdown >
                  <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    <img src={dropdownIcon} className="dropdownIconImage" alt="Print Prescription" title="Print Prescription" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu as={CustomMenu} >
                      <Dropdown.Item bsPrefix={(props.isPrintActive)?'printButton':'greyButton'}  onClick={()=>props.openPrintPage()} disabled={!props.isPrintActive} >
                         <img src={printerIcon} alt="" title={(!props.isPrintActive)?'print not active':''}/>
                         <span >Print</span>
                         {/* <span className={}>Print</span> */}
                      </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> : null}
        </div>) :null}
      {(props.category==1) ? (localStorage.getItem('payment_visibility')==="true") ? (
      <div className="apptUpdatedtm">
        <img src={editPaymentIcon} className="edit_payment_icon" onClick={()=>props.updatepayment()} />
      </div>):(
      <div className="apptUpdatedtm">{props.updatedTime}</div>): null}
      {(props.category==2) ? (<div className="apptUpdatedtm">{props.updatedTime}</div>
      ): null}
      {(localStorage.getItem('scan_visibility')==='true' && props.category==1) ? <div onClick={()=>props.showPrescription()} className="scannedrx">Rx</div> : null}
      </div>
    )
}

export default PatientAppointment;

