import React, { Component } from 'react';
import './SelectSlot.css';
import moment from 'moment';
import DateCard from './DateCard/DateCard';
import TimeShift from './TimeShift/TimeShift';
import { connect } from 'react-redux';
import axios from '../../../axios-order';
import Spinner from '../../../assets/images/spinner.gif';
import previousIcon from '../../../assets/images/icons/previous.png';
import {Modal, Button} from 'react-bootstrap';
// import ScrollableAnchor from 'react-scrollable-anchor';
import scrollIntoView from 'scroll-into-view';

const AddSlot = ({isActive, hideModal, addSlots, slotsNumber, addSlotNumber, selectedSlotNumber, selectSlotNumber, maxSlotsToAdd}) => {
    const isGreateThanMax = (slotsNumber > maxSlotsToAdd);
    slotsNumber = (isGreateThanMax) ? maxSlotsToAdd : slotsNumber;
    const slotButton = new Array(slotsNumber).fill(null).map((slot, index)=>{
        return <Button className={'slotNumber ' + ((index===selectedSlotNumber) ? 'focusSlot' : '')} key={'slotButton'+index} variant="outline-primary" onClick={()=>selectSlotNumber(index)}>{index+1}</Button>
    })
    return(
        <Modal className="addSlotModal" show={isActive} onHide={hideModal} animation={true} centered>
            <Modal.Header closeButton>
                Add Slots
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <div className="addSlotModalcontentDesc">Number of slots to add</div>
                    {slotButton}
                    { (!isGreateThanMax)  ? <Button className="slotNumber" variant="outline-primary" onClick={()=>addSlotNumber()}>+</Button> : null}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div>
                <Button className="addSlotModalBtn" variant="outline-primary" disabled={selectedSlotNumber==null || selectedSlotNumber<0} onClick={()=>addSlots()}>Add Slots</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}


class SelectSlot extends Component {
    state = {
        slots : null,
        previosAppointments : null,
        selectedShift : null,
        selectedSlot : null,
        actualShifts:null,
        bookingData:null,
        shiftsByTime:null,
        isModalActive:false,
        slotsNumber:8,
        selectedSlotNumber : 8,
        maxSlotsToAdd : 14,
        slotTime : 6,
        customStartTime:null,
        customEndTime :"23:00:00",
        customShiftId :null,
        isLoaderActive:false,
        allBooked:true,
        isSelectingSlot :false
    }

    getupcommingappointments(docid, addid, date){
        const forDate = new moment(date).format('YYYY-MM-DD');
        this.setState({isLoaderActive:true});
        axios.post('/get_upcoming_appointments_web', {"id" : docid, "for_date" : forDate, "page_num" : null, "address_id" : addid})
        .then((response)=>{
            const appointments = (typeof(response.data)=='string')?{}:response.data;
            this.setState({previosAppointments : appointments})
            console.log(this.state.bookingData.meanBuffer);
            // debugger;
            this.createSlotsHandler("07:00:00","23:59:00", this.state.bookingData.meanBuffer);
            // this.createSlots(this.findslottime(this.state.bookingData));
            // if(this.state.actualShifts && this.state.actualShifts.length>0){
            //     this.createSlotsHandler(this.state.actualShifts[0].starttime, this.state.actualShifts[0].endtime, this.state.bookingData.meanBuffer.filter((item)=>{
            //         return item.day === new moment(this.props.selectedDate).format('ddd').toUpperCase();
            //}))
            // }   
            this.setState({isLoaderActive:false});      
        }, (error) => {
            //Error Instructions
            alert("Unable to get data . Kindly reload the page");   
        })
    }

    //Create Slots method
    createSlots(slotTime){
        console.log(slotTime);
    }

    updateddayshift(hours, extendedhour, isToday, selectDate) {
        //Get epoch time of UTC Time.
        let uhours = Array.from(Object.create(hours));
        const date = new Date();
        const existedHours = [];
        for(let j=0;j<uhours.length;j++){
            const endTimeArr = uhours[j].endtime.split(":");
            const endTime = date.setHours(parseInt(endTimeArr[0]), parseInt(endTimeArr[1], 0, 0))
            if(endTime > Date.parse(new Date()) || !isToday){
                existedHours.push(uhours[j])
            }
        }
        
        if(true){
            for(var i=0;i<extendedhour.length;i++){
                //Update shift start & end time for same shift id.
                for(var j = 0;j<existedHours.length;j++){
                    if((new moment(extendedhour[i].start_date).format('YYYY-MM-DD')===new moment(selectDate).format('YYYY-MM-DD')) && (existedHours[j].shiftid === extendedhour[i].shiftid)){
                        //update hours with extended hours time for shift with same shiftid
                        existedHours[j].starttime = (extendedhour[i].starttime>existedHours[j].starttime)?existedHours[j].starttime:extendedhour[i].starttime;
                        existedHours[j].endtime = (extendedhour[i].endtime<existedHours[j].endtime)?existedHours[j].endtime:extendedhour[i].endtime;
                    }
                }
            }
        
        }
        return existedHours;
    }

    isshiftexist(shiftDays, date){
        // console.log(shiftDays, date);   
        //Returns boolean value
        const binValArr = shiftDays.toString('2').split('');
        const arrLength = 7-binValArr.length;
        for(let j=0;j<arrLength;j++) {
            binValArr.unshift(0);
        }
        return binValArr[6-date.getDay()]
    }
    
    shiftsforday(shifts, date){     
        //for no shift shifts
        if(shifts.length===0)
            return [];

        const shiftsForDay = [];
        for(let i=0;i<shifts.length;i++){
            const isShiftActive = this.isshiftexist(shifts[i].days, date);
            if(isShiftActive){
                shiftsForDay.push(shifts[i]);
            }
        }
        return shiftsForDay
    }

    getbookingdata(docId, addId, selectedDate){
        axios.get('/getdoctordetailsforbook_v3/'+docId+'/'+addId)
        .then((response)=>{
            const responseData = response.data.doctor_details_for_book;
            
            //Actual shifts for which day doctor is available and current time is greater
            let actualShifts = this.updateddayshift(
                this.shiftsforday(responseData.hours, selectedDate),
                responseData.customHours,
                selectedDate.setHours(0,0,0,0)===new Date().setHours(0,0,0,0) , selectedDate);
            // console.log('actual shifts', actualShifts);
            actualShifts = actualShifts.sort(function(a, b){
                const aArr = a.starttime.split(':');
                const bArr = b.starttime.split(':');
                return new Date().setHours(parseInt(aArr[0]), parseInt(aArr[1], 0, 0)) - new Date().setHours(parseInt(bArr[0]), parseInt(bArr[1], 0, 0)) 
            })
            // let actualShifts = {
            //     starttime : "06:00:00", 
            //     endtime : "11:59:00" ,
            //     days : 127
            // }
            console.log('ACTUAL SHIFTS', actualShifts);
            // console.log('actual shift', actualShifts);
            this.setState({allBooked:true, bookingData : responseData, actualShifts :actualShifts, shiftsByTime:this.createslotsbyshifts(actualShifts)}, ()=>{
                this.getupcommingappointments(docId, addId, selectedDate);
            });
        }, (error)=>{
            //Error Instructions
            alert("Unable to get data . Kindly reload the page");
        })
    }

    isslotinshift(starttime, endtime){
        console.log(this.state.actualShifts);
        console.log(starttime, endtime);
        const actualShifts = this.state.actualShifts;
        for(let i=0;i<actualShifts.length;i++){
            const startShift = this.props.selectedDate.setHours(parseInt(actualShifts[i].starttime.split(':')[0]), parseInt(actualShifts[i].starttime.split(':')[1]), 0, 0);

            const endShift = this.props.selectedDate.setHours(parseInt(actualShifts[i].endtime.split(':')[0]), parseInt(actualShifts[i].endtime.split(':')[1]), 0, 0);
            //this.state.actualShifts[i].startime;
            
            console.log(starttime, endtime, startShift, endShift);
            if(starttime >= startShift && endtime<=endShift){
               return true
            }
        }
        return false

    }

    //Divide shifts into time of day
    createslotsbyshifts(shifts){
        let dayshifts = {
            'morning' : [],
            'afternoon' : [],
            'evening' : [],
            'night' : []
        };
        for(let i=0;i<shifts.length;++i){
            const startTime = parseInt(shifts[i].starttime.split(':')[0]);
            let dayTime = null;
            if(startTime > 0 && startTime < 11){
                dayTime = 'morning';
            } else if (startTime >= 11 && startTime < 15){  
                dayTime = 'afternoon';
            } else if (startTime >= 15 && startTime < 19) {
                dayTime = 'evening';
            } else {
                dayTime = 'night';
            }

            dayshifts[dayTime].push({
                starttime : shifts[i].starttime,
                endtime : shifts[i].endtime,
            });
        }
        return dayshifts
    }

    componentDidMount() {
        // console.log(this.props);
        //Find out shifts and make slots
        if(this.props.appointments.isQuickAdd){
            this.setState({'isSelectingSlot' : true});
        }
        this.getbookingdata(this.props.docId, this.props.addId, this.props.selectedDate)
    }
    

    // findslottime(i, meanBuffer) {
    //     //console.log(meanBuffer);
    //     const buffer = meanBuffer[0]['buffer'+(i-6)*2];
    //     const mean = meanBuffer[0]['mean'+(i-6)*2];
    //     return mean + buffer;

    //     // const mean = meanArray[(i-6)*2];
    //     // const buffer = bufferArray[(i-6)*2];
    //     // const slotTime = mean + buffer;
    //     // console.log('slotTime', slotTime)
    //     // return slotTime;
    // }

    findslottime(i, meanBuffer) {
        //console.log(meanBuffer);
        const buffer = meanBuffer[0]['buffer'+(i-6)*2];
        const mean = meanBuffer[0]['mean'+(i-6)*2];
        return mean + buffer;
        // const mean = meanArray[(i-6)*2];
        // const buffer = bufferArray[(i-6)*2];
        // const slotTime = mean + buffer;
        // console.log('slotTime', slotTime)
        // return slotTime;
    }

    checkslotavailability(slotStartTime, slotEndTime, hour) {
        const previosAppointmentsForDay = (this.state.previosAppointments) ? this.state.previosAppointments.appointments:null;
        if(previosAppointmentsForDay && previosAppointmentsForDay.length){
            for(let k=0;k<previosAppointmentsForDay.length; ++k){
                console.log(new moment(previosAppointmentsForDay[k].appointment_datetime.replace('Z', '')).format('hh : mm'));                
                if((new moment(previosAppointmentsForDay[k].appointment_datetime.replace('Z', '')).format('HH : mm') === new moment(slotStartTime).format('HH : mm')) ){
                    console.log(new moment(slotStartTime));
                return true}
            }
        }
        return false;
    }
    
    // createSlotsHandler(startShiftTime, endShiftTime, meanBuffer){
    //     this.setState({allBooked:true}, ()=>{
    //     const selectedDate = new Date(this.props.selectedDate);
    //     console.log(selectedDate);
    //     const startShiftHour = parseInt(startShiftTime.split(':')[0]);
    //     const endShiftHour = parseInt(endShiftTime.split(':')[0]);
    //     const startShift = selectedDate.setHours(startShiftHour, parseInt(startShiftTime.split(':')[1]), 0, 0); 
    //     const endShift = selectedDate.setHours(endShiftHour, parseInt(endShiftTime.split(':')[1]), 0, 0);
    //     const isToday = selectedDate.setHours(0,0,0,0)===new Date().setHours(0,0,0,0);
    //     // console.log('start shift & end shift', startShift, endShift);
    //     // console.log(endShift);  
    //     let shiftSlotObj = [];
    //     let allBooked = true;
    //     for(let i=startShiftHour;i<=endShiftHour;++i){
    //         const slottime = this.findslottime(i, meanBuffer);
    //         this.setState({slotTime : slottime});
    //         const slotsHourArr = [];
    //         const slotsInHour = Math.floor(60/slottime);
    //         for(let j=1;j<=slotsInHour;++j){
    //             const slot = {
    //                 slotStartTime : selectedDate.setHours(i,0,0,0)+ (j-1)*60*1000*slottime,
    //                 slotEndTime : selectedDate.setHours(i,0,0,0) + j*60*1000*slottime,
    //             }
               
    //             slot.isExist = !((selectedDate.setHours(0,0,0,0)===new Date().setHours(0,0,0,0)) && ((slot.slotEndTime <= new Date().getTime()) || (slot.slotStartTime < new Date().getTime())));     
    //             slot.isBooked = this.checkslotavailability(slot.slotStartTime, slot.slotEndTime, i);
    //             // if(slot.slotEndTime <= (this.state.selectedDate.setHours(i+1,0,0,0)) &&  slot.slotStartTime >= startShift && slot.slotEndTime <= endShift)
    //             if((slot.slotEndTime <= (selectedDate.setHours(i,0,0,0) + (24*60*60*1000))) && slot.slotStartTime >= startShift && slot.slotEndTime <= endShift && (!isToday || slot.slotStartTime>=Date.parse(new Date()))) {
    //                 if(slot.isExist && !slot.isBooked){
    //                     allBooked = false;
    //                 }
    //                 slotsHourArr.push(slot);
    //             }
                    
    //         }
    //         // console.log(slotsHourArr);
    //         if(slotsHourArr.length){
    //             shiftSlotObj[(i).toString()] = slotsHourArr;
    //         }
    //         this.setState({allBooked : allBooked});
    //     }
    //     this.setState({slots : shiftSlotObj, selectedShift : startShiftTime, selectedSlot : null}, ()=>{
    //         const actualShifts = this.state.actualShifts;
    //         const currentShift = actualShifts.filter((shift)=>shift.starttime===this.state.selectedShift)[0];
    //         const currentShiftIndex = actualShifts.indexOf(currentShift);
    //         const nextShiftIndex = (actualShifts.length>(currentShiftIndex+1)) ? currentShiftIndex+1:null;
    //         const nextShiftStartTime = (nextShiftIndex) ? actualShifts[nextShiftIndex].starttime : null;
    //         const maxSlotsToAdd = this.maxslotstoadd(currentShift.starttime, currentShift.endtime, nextShiftStartTime, this.state.slotTime);//currentShiftEndTime, nextShiftStartTime, slotTime
    //         this.setState({
    //             maxSlotsToAdd:maxSlotsToAdd, 
    //             customStartTime:currentShift.endtime,
    //             customShiftId:currentShift.shiftid
    //         })
    //     })
    //     })
    // }

    createSlotsHandler(startShiftTime, endShiftTime, meanBuffer){
        this.setState({allBooked:true}, ()=>{
        const selectedDate = new Date(this.props.selectedDate);
        // console.log(selectedDate);
        const startShiftHour = parseInt(startShiftTime.split(':')[0]);
        const endShiftHour = parseInt(endShiftTime.split(':')[0]);
        const startShift = selectedDate.setHours(startShiftHour, parseInt(startShiftTime.split(':')[1]), 0, 0);
        const endShift = selectedDate.setHours(endShiftHour, parseInt(endShiftTime.split(':')[1]), 0, 0);
        const isToday = selectedDate.setHours(0,0,0,0)===new Date().setHours(0,0,0,0);
        // console.log('start shift & end shift', startShift, endShift);
        // console.log(endShift);
        let shiftSlotObj = [];
        let allBooked = true;
        // debugger;
        for(let i=startShiftHour;i<=endShiftHour;++i){
            const slottime = this.findslottime(i, meanBuffer);
            // console.log('start shift hour', i);
            // this.setState({slotTime : slottime});
            const slotsHourArr = [];
            const slotsInHour = Math.floor(60/slottime);
            for(let j=1;j<=slotsInHour;++j){
                const slot = {
                    slotStartTime : selectedDate.setHours(i,0,0,0)+ (j-1)*60*1000*slottime,
                    slotEndTime : selectedDate.setHours(i,0,0,0) + j*60*1000*slottime,
                }
                slot.isInShift = this.isslotinshift(slot.slotStartTime, slot.slotEndTime);
                slot.isExist = !((selectedDate.setHours(0,0,0,0)===new Date().setHours(0,0,0,0)) && ((slot.slotEndTime <= new Date().getTime()) || (slot.slotStartTime < new Date().getTime())));
                // console.log(new Date(slot.slotStartTime));     
                slot.isBooked = this.checkslotavailability(slot.slotStartTime, slot.slotEndTime, i);
                // if(slot.slotEndTime <= (this.state.selectedDate.setHours(i+1,0,0,0)) &&  slot.slotStartTime >= startShift && slot.slotEndTime <= endShift)
                if((slot.slotEndTime <= (selectedDate.setHours(i,0,0,0) + (24*60*60*1000))) && slot.slotStartTime >= startShift && slot.slotEndTime <= endShift && (!isToday || slot.slotStartTime>=Date.parse(new Date()))) {
                    if(slot.isExist && !slot.isBooked){
                        allBooked = false;
                        if(!this.props.appointments.rescheduleAppt && this.props.appointments.isQuickAdd){
                            this.props.selectSlot(slot.slotStartTime);
                            return null;
                        }
                    }
                    slotsHourArr.push(slot);
                }
                
            }
            console.log('Slots Array', slotsHourArr);
            if(slotsHourArr.length){
                shiftSlotObj[(i).toString()] = slotsHourArr;
            }
            this.setState({allBooked : allBooked});
        }
        this.setState({slots : shiftSlotObj, selectedShift : startShiftTime, selectedSlot : null}, ()=>{
            const actualShifts = this.state.actualShifts;
            // const currentShift = actualShifts.filter((shift)=>shift.starttime===this.state.selectedShift)[0];
            // const currentShiftIndex = actualShifts.indexOf(currentShift);
            // const nextShiftIndex = (actualShifts.length>(currentShiftIndex+1)) ? currentShiftIndex+1:null;
            // const nextShiftStartTime = (nextShiftIndex) ? actualShifts[nextShiftIndex].starttime : null;
            // const maxSlotsToAdd = this.maxslotstoadd(currentShift.starttime, currentShift.endtime, nextShiftStartTime, this.state.slotTime);//currentShiftEndTime, nextShiftStartTime, slotTime
            // this.setState({
            //     maxSlotsToAdd:maxSlotsToAdd, 
            //     customStartTime:currentShift.endtime,
            //     customShiftId:currentShift.shiftid
            // })
            })
        })
        
    }

    selectSlotHandler(slotTime) {
        this.setState({selectedSlot : slotTime});
        // console.log(this.props);
        this.props.selectSlot(slotTime);
    }

    getDayValue(dayVal){
        return 3
    }

    addSlotHandler(selectedDate){
        // console.log(selectedDate);
        //Check can be added or not
        const startshiftHr = this.state.customStartTime.split(':')[0];
        const startshiftMin = this.state.customStartTime.split(':')[1];
        const endShiftTime = selectedDate.setHours(parseInt(startshiftHr), parseInt(startshiftMin), 0, 0) + (this.state.selectedSlotNumber+1)*this.state.slotTime*60*1000;
        const dataObj = {
            "doctor_id" : this.props.docId,
            "address_id": this.props.addId,
            "starttime" : this.state.customStartTime,
            "endtime" :new moment(endShiftTime).format("HH:mm")+':00',
            "start_date" : new moment(selectedDate).format('YYYY-MM-DD'),
            "end_date":new moment(selectedDate).format('YYYY-MM-DD'),
            "days":this.getDayValue(new Date().getDay()),
            "shiftid":this.state.customShiftId
        }
        axios.post("/postCustomWorkingHours", dataObj)
        .then((response) => {
            this.setState({isModalActive:false});
            this.getbookingdata(this.props.docId, this.props.addId, this.props.selectedDate)
        }, (error)=>{
            alert("Unable to add slots. Error Occoured.");
        })
    }

    maxslotstoadd(prevStartTime, prevShiftEndTime, nextShiftStartTime, slotTime){
        let startTime;
        let endTime;
        // const prevStartTimeHr = parseInt(prevStartTime.split(':')[0]);
        // const prevStartTimeMin = parseInt(prevStartTime.split(':')[1]);        
        // const currentHrs= new Date().getHours();
        // const currentMin = new Date().getMinutes();
        // if((prevStartTimeHr > currentHrs) && (prevStartTimeMin > currentMin)){
        //     startTime = new Date().setHours(currentHrs, currentMin, 0, 0);
        //     const nextStartShifTimeArr = (prevStartTime) ? prevStartTime.split(':') : [23 ,59]; 
        //     endTime = new Date().setHours(parseInt(nextStartShifTimeArr[0]), parseInt(nextStartShifTimeArr[1]),0,0);
        // } else {
            const prevShifTimeArr = prevShiftEndTime.split(':');
            const nextShifTimeArr = (nextShiftStartTime) ? nextShiftStartTime.split(':') : [23 ,0];   
            startTime = new Date().setHours(parseInt(prevShifTimeArr[0]), parseInt(prevShifTimeArr[1]), 0, 0);
            endTime = new Date().setHours(parseInt(nextShifTimeArr[0]), parseInt(nextShifTimeArr[1]), 0, 0);
        //}
        const result = parseInt((endTime-startTime)/(slotTime*60*1000));
        return (result<0) ? 0 : result;
    }

    scrollToTimeHandler(time) {
        const element = document.getElementsByClassName('slotTime');
    }

    scrolltoshift(hour) {
        console.log(hour, new Date().getHours());
        const currentHour = new Date().getHours();
        if(hour < currentHour && this.props.selectedDate.setHours(0,0,0,0)===new Date().setHours(0,0,0,0)){
            hour = currentHour;
        }
        console.log('HOUR IS', hour);
        const elem = document.getElementById('section'+hour);
        scrollIntoView(elem, {
            align:{
                top: 0
            },
        });
    }

    render() {
        //Shift By Time Component
        let shiftsTemplate = (this.state.isLoaderActive) ? <div class="shiftLoader"><img src={Spinner} alt=""/></div> : null;
        if(this.state.actualShifts && this.state.actualShifts.length>0 && this.state.shiftsByTime){
            shiftsTemplate = Object.keys(this.state.shiftsByTime).map((time, index)=>{
                let shiftsInTime = this.state.shiftsByTime[time];
                let shiftsView = shiftsInTime.map((shift)=>{                
                    let starttime = new moment().set({hour:parseInt(shift.starttime.split(':')[0]), minute:parseInt(shift.starttime.split(':')[1])}).format('hh:mm a'); 
                    let endtime = new moment().set({hour:parseInt(shift.endtime.split(':')[0]), minute:parseInt(shift.endtime.split(':')[1])}).format('hh:mm a');
                    return <TimeShift isSelected = {this.state.selectedShift===shift.starttime} key = {starttime} startTime={starttime} endTime={endtime} startHour={shift.starttime.split(':')[0]}
                    click = {()=>{
                        //calculate starttime hour
                        const startHour = shift.starttime.split(':')[0];
                        this.scrolltoshift(parseInt(startHour));
                        this.setState({selectedShift:shift.starttime})
                        // this.location.href='#time'+startHour; 
                    }}
                    // click={() => this.createSlotsHandler(shift.starttime, shift.endtime,this.state.bookingData.meanBuffer.filter((item)=>{
                    //     return item.day === new moment(this.props.selectedDate).format('ddd').toUpperCase();
                    // }))}
                    />
                })
                if(shiftsInTime.length){
                    return(
                        <div className="selectShiftCard" key={time+index}>
                            <div className="shiftTime">
                                {time} shift
                            </div>
                            {shiftsView}
                        </div>
                    )
                } else {
                    return null
                }                    
            })
        };
        
        const slotsTemplate = (this.state.slots) ? Object.keys(this.state.slots).map((slotHour)=>{
            console.log('Slots', this.state.slots);
            const slotView = this.state.slots[slotHour].map((slot, index) => {
            // console.log(slot, this.state.allBooked);
            let slotClass='slot';
            if(!slot.isExist) {
                slotClass='slot slotInactive';
            } else if(slot.isBooked){
                slotClass='slot slotBooked';
            } else {
                slotClass='slot activeSlot';
            }
            const starttime=new moment(slot.slotStartTime).format('hh:mm a');
            return (
                <div key={'key'+slot.slotStartTime} class={slotClass} onClick={() => {
                    if(!slot.isBooked && slot.isExist){
                        return this.props.selectSlot(slot.slotStartTime);
                    }
                }
                }><p style={(!slot.isInShift) ?{'borderColor' : '#d6d6d6', 'color' : '#939393'} : {}}>{starttime}</p></div>
            )
        });
            
            return (
                <div class="slotsInThisHour" key={'section'+parseInt(slotHour)}>
                    <h4 id={'section'+parseInt(slotHour)}>{new moment({hour:slotHour, minute:0, second:0, millisecond: 0}).format('hh:mm a')}</h4>
                    <div class = "slotsRow">
                        {slotView}
                    </div>
                    
                </div>
            )        
            
        }) : this.state.slots;

        //create week template
        const weekTemplate =[];
        let dateSelected = new moment(this.props.date.date);
        let todayDate = new moment();
        // console.log(dateSelected.diff(todayDate, 'days'));
        let startDate = (dateSelected.diff(todayDate, 'days')>=6) ? dateSelected.subtract(3, 'days') : todayDate;
        for(var i=0;i<7;++i){
            const date = new moment(startDate).add(i, 'days');
            let isSelectedDate = date.date()===this.props.selectedDate.getDate();
            weekTemplate.push(<div onClick={()=>{
                this.getbookingdata(this.props.docId, this.props.addId, new Date(date));
                this.props.selectDate(new Date(date));
            }} key={'date'+(i+1)}><DateCard key={'date_'+(i+1)} date={date} isSelected={isSelectedDate}/></div>);
        }

        return(
            <div>
                {(this.props.appointments.isQuickAdd && !this.props.appointments.rescheduleAppt) ? <div className="settingSlotModal">Choosing Slot...</div>:null}
                <div className = "createApptHead">
                <img src={previousIcon} alt="Back" onClick={()=>this.props.goBack()}/>
                    Choose Slot
                </div>
                <div className = "availableSlots">
                    <div className="slotDate">
                        <h3 className = "slotTimeHeading">Select Date</h3>
                        <div>
                            {weekTemplate}
                        </div>
                    </div>
                    <div className="slotShifts">
                        <h3 class = "slotTimeHeading">Select Shift</h3>
                        <div>
                            {(shiftsTemplate) ? shiftsTemplate : <div className="noShiftExist">
                                No Shifts Exist
                            </div>}
                        </div>                  
                    </div>
                    <div className="slotTime">
                        <h3 class = "slotTimeHeading">
                            <span>Select Slot</span> 
                        </h3>
                        {/* <p onClick={()=>this.scrolltoshift()}>Choose slot</p> */}
                        <div>
                            {(this.state.isLoaderActive) ? <div class="shiftLoader"><img src={Spinner} alt="Loading..."/></div>: (slotsTemplate) ? slotsTemplate : <div className="noSlotExist">No Slots Exist</div>}
                            {(this.state.maxSlotsToAdd>0 && slotsTemplate) ? <div className="addslot-btn-container">
                                {/* <div className="addslot-btn" onLoad={()=>{
                                }} onClick={()=>{
                                    this.setState({
                                        isModalActive:!this.state.isModalActive,
                                        selectedSlotNumber : null     
                                    })
                                }}>Add Slots</div> */}
                            </div> : null}
                        </div>
                    </div>
                </div>
                <AddSlot 
                    slotsNumber={this.state.slotsNumber} 
                    addSlotNumber={()=>{
                        this.setState({slotsNumber : this.state.slotsNumber + 8})
                    }}
                    isActive={this.state.isModalActive} 
                    selectSlotNumber = {(selected)=>this.setState({selectedSlotNumber : selected})} 
                    maxSlotsToAdd = {this.state.maxSlotsToAdd} 
                    selectedSlotNumber = {this.state.selectedSlotNumber}
                    hideModal={()=>{this.setState({isModalActive:!this.state.isModalActive})}} 
                    addSlots = {()=>this.addSlotHandler(this.props.selectedDate)}
                />
            </div>
            
        )
    }
   
}

const mapStateToProps = (state, props) => {
    return {
        date : state.date,
        appointments : state.appointmentData
    }
}

export default connect(mapStateToProps, null)(SelectSlot)