export const checkforname = (value) => {
    // const result = false;
    if(typeof(value)=='string'){
        if(true){
            if(value.match(/^[a-zA-Z ]*$/)){
                return true
            }
            else{
                return false
            }
                
        }
    }
}

export const checkforcity = (value) => {
    if(typeof(value)=='string'){
        if(true){
            if(value.match(/^[a-zA-Z ]*$/)){
                return true
            }
            else{
                return false
            }
                
        }
    }
}

export const checkfornumber = (value) => {
    const result = false;
    console.log(value.match(/^[0-9]*$/) )
    if(typeof(value)=='string'){
        if(true){
            if(value.match(/^[0-9]*$/))
                return true
            else 
                return false
        }
    }
    // if(true){
    //     if(value.match(/^[0-9]*$/))
    //         return true
    //     else 
    //         return false
    // }
}

export const checkNumberLength = (number) => {
    // let pattern = /^\d{10}$/;
    // if(number.match(pattern)){
    //     return true
    // }else{
    //     return false
    // }
    if(number.length>10){
        return false;
    }else{  
        return true
    }
}