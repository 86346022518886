import React, {Component} from 'react';
import {connect} from 'react-redux';
import paymentStyle from './PatientProfile.css';
import { Col } from 'react-bootstrap';
import patIcon from '../../assets/images/patientImage.png';
import Invoice from '../../components/invoice/invoice';
import {Link} from 'react-router-dom';
import editIconImage from '../../assets/images/icons/edit-white.svg';
import printerIconImage from '../../assets/images/icons/printer.svg'
import axios from '../../axios-order';
import moment from 'moment';

/**
 * This method is rendering left service item in payment card
 * @param {*} param0 
 */

// function ServiceItem(medicalService){
//     console.log(medicalService);
//     // debugger;
//     // if(!medicalService || (medicalService && medicalService.amount===0)){
//     //     return(
//     //         <p className="serviceItem">
//     //             <span className="serviceName"></span>
//     //         </p>
//     //     )
//     // }
//     return(
//         <>
//             {/* <p className="serviceItem">
//                 <span className="rupees">Rs</span>
//                 <span className="payment">{medicalService.amount}</span>
//                 <span className="hyphen">-</span>
//                 <span className="serviceName">{medicalService.serviceName}</span>
//             </p> */}
//         </>
//     )
// }

// function ShowServiceItem({services}){
//     console.log('SERVICES AVAILABLE', services);
//     let serviceItem = services.map((service,index)=>{
//         // console.log(service);
//         return <ServiceItem 
//             key={index}
//             serviceName={service.service_name}
//             serviceCost={service.price}
//             unit={service.units}
//             total={service.units * service.price}
//         />
//     })
//     return serviceItem;
// }

class DateMonth extends Component{
    render(){
        return(
            <div className="invoiceDateContainer"> 
                <div className="invoiceMonthDate"> 
                    Oct, 2019 
                </div>
             </div>
        );
    }
}

class Appointments extends Component{
    render(){
        return(
            <div> 
                Appointment
            </div> 
        );
    }
}

function  TabDetail({tabName, invoices, editInvoice}){
    // console.log(tabName, invoices);
    let tabDetail = (tabName==='APPOINTMENTS') ? <Appointments />: 
    <PatientInvoices 
        invoices={invoices} 
        editInvoice={(invoice)=>editInvoice(invoice)}  
    />; 
    return tabDetail
}

class TabDetails extends Component{
    render(){
        let {tabName} = this.props;
        let {invoices} = this.props
        // console.log(tabName, invoices);
        return(
            <div className="tabDetails"> 
                <TabDetail 
                    tabName={tabName} 
                    invoices={invoices} 
                    editInvoice={(invoice)=>this.props.editInvoice(invoice)} 
                /> 
            </div> 
        ); 
    } 
} 

function PaymentSummary(props){ 
    // console.log(this.props);
    // debugger;
    return (
        <div className="invoicePaymentDetails">
            <div className="invoicePaymentDetailsContainer">
                <div className="billedPayment">
                    <div className="label">
                        Billed Amount
                    </div>
                    <div className="billedAmount amount"> 
                        {props.billedAmt} 
                    </div>
                </div>
                <div className="discount">
                    <div className="label discountLabel">
                        Discount
                    </div>
                    <div className="discountAmount amount">
                        -{props.discount}
                    </div>
                </div>
                <div className="total">
                    <div className="label">
                        Total Amount
                    </div>
                    <div className="totalAmount amount">
                        {props.totalAmt}
                    </div>
                </div>
                <div className="paid">
                    <div className="label">
                        Paid Amount
                    </div>
                    <div className="paidAmount amount">
                        {props.paidAmount} 
                    </div>
                </div>
                <div className="balance">
                    <div className="label balanceLabel">
                        {/* {(invoice.advance)?'Advance':'Balance'} */}
                    </div>
                    
                    {/* <div className={(invoice.advance)?'balanceAmount amount green':'balanceAmount amount red'}>
                        {(invoice.advance)?invoice.advance:invoice.balance}
                    </div> */}
                </div>
            </div>
        </div>
    )
}

class InvoiceBody extends Component{
   
    render(){
        let invoice=this.props.invoice;
        console.log(invoice);
        // debugger;
        let services=this.props.invoice.billing;
        console.log('SERVICES FOR BILLING ARE', services);
        return(
            <div className="invoiceDetailsContainer">
                <div className="invoiceTable">
                    <table>
                        <thead>
                        <tr className="tableHeading">
                                <th className="itemHeading">Item</th>
                                <th className="costHeading">Cost</th>
                                <th className="unitHeading">Unit</th>
                                <th className="totalHeading">Total</th>
                        </tr>
                        </thead>
                        <tbody>
                            {services.map(service=>{
                                return (
                                    <tr>
                                        <td className="serviceName">{service.service_name}</td>
                                        <td>{service.price}</td>
                                        <td>{service.units}</td>
                                        <td>{service.units * service.price}</td>
                                    </tr>
                                )   
                            })}
                        </tbody>
                    </table>
                </div>
                <PaymentSummary 
                    totalAmt={invoice.totalAmount} 
                    paidAmount={invoice.paidAmount} 
                    discount={(invoice.discount) ? invoice.discount.bill_discount : null} 
                    balance={invoice.balance} 
                    billedAmt={invoice.billedAmount} /> 
            </div>  
        );
    }
}

class InvoicesHeader extends Component{
    render(){
        return(
            <div>
                <MainHeader />
                <DateMonth />    
            </div>
        );
    }
}

class InvoiceHeader extends Component{
    render(){
        console.log(this.props);
        // debugger;
        let {invoiceNo} = this.props;
        let {invoice} = this.props;
        // console.log(invoice);
        // let {invoiceDateTime} = this.props;
        // let {invoiceBill} = this.props;
        let advance = this.props.advance;
        let balance = this.props.balance;
        // console.log('Advance or balance is ',advance, balance);
        return(
            <div className="invoiceHeaderContainer">
                <div className="invoiceHeaderLeft">
                    <div className="invoiceNumber">
                        {invoiceNo}
                    </div>
                    <div className="invoiceDateTime">
                        <div className="invoiceDate">
                            13 Oct, 2019
                        </div>
                        <div className="invoiceTime">
                            12:30 PM
                        </div>
                    </div>
                </div>
                <div className="invoiceHeaderRight">
                    <div className="invoiceCollectRefund" onClick={()=>this.props.setModalShow(true, invoice)}>
                        <div className="invoiceCollectRefundLabel">
                            {(advance)?'Refund':'Collect'}
                        </div>
                        <div className="invoiceCollectRefundPayment">
                            <div className="currencySymbol">
                                &#8377;
                            </div>
                            <div className="invoiceCollectRefundAmount">
                                500
                            </div>
                        </div>
                    </div>
                    <div className="editInvoiceIcon">
                        <img src={editIconImage} className="editIconImage" alt="Edit" onClick={()=>this.props.editInvoice(invoice)} />
                    </div>
                    <div className="printInvoiceIcon">
                        <img src={printerIconImage} className="printerIconImage" alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}

class MainHeader extends Component{
    render(){
        return(
            <div className="invoicesHeader">
                <div className="invoicesLeft">
                    Invoices
                </div>
                <div className="invoicesRight">
                    <div className="collectRefundPayment">
                        <span className="collectRefundLabel">Collect</span>
                        <span className="redColor">&#8377;</span>
                        <span className="collectRefundAmount redColor">3000</span>
                    </div>
                    <div onClick={()=>this.props.addInovice(this.props.selectedPatientId)} className="addInvoiceBtn">Add Invoice</div> 
                </div>
            </div>
        )
    }
}


class PatientInvoice extends Component{

    render(){
         let {invoice} = this.props;
        // console.log(invoice);
        return(
            <div className="patientInvoice">
                <InvoiceHeader
                    invoice={invoice}
                    invoiceNo = {invoice.invoiceNo}
                    invoiceDateTime= {invoice.invoiceDateTime}
                    advance={invoice.advance}
                    balance={invoice.balance}
                    setModalShow={this.props.setModalShow}
                    editInvoice={(invoice)=>this.props.editInvoice(invoice)}
                />
                <InvoiceBody 
                    invoice={invoice}
                />
            </div>  
        )
    }
}

class PatientInvoices extends Component{

    constructor(props){
        super(props);
        this.state = {

        }
    }

    // setModalShow(isShow){
    //     console.log(isShow);
    // }

    render(){
        let {invoices} = this.props;
        // console.log(invoices);
        let patInvoice = invoices.map((invoice)=>{
            return(
                <PatientInvoice 
                    key={invoice.invoiceNo} 
                    invoice = {invoice} 
                    setModalShow = {this.props.setModalShow} 
                    editInvoice={(invoice)=>this.props.editInvoice(invoice)} 
                />
            )
        })
        return(
            <>
                <div className="patientInvoicesContainer">
                    {/* <InvoicesHeader 
                    /> */}
                    <div className='patientInvoicesList'>
                        {patInvoice}
                        {/* PatientInvoice */}
                    </div>
                </div>
            </>
        )
    }
}


function Tab({tab, index, handleInvoiceTab}){
    return(
        <div 
            key={index} 
            className={tab.isTabActive===true?'invoiceTab active':'invoiceTab'}  
            onClick={()=>handleInvoiceTab(index, tab)} 
        > 
           {tab.tabName} 
        </div> 
    )
}

function ShowTab({tabs, handleInvoiceTab, handleChangeTabName}){
    console.log(tabs);
    let tab = tabs.map((tab, index)=>{
        return(
            <Tab 
                key={index} 
                index={index} 
                tab={tab} 
                handleInvoiceTab={handleInvoiceTab}  
                handleChangeTabName={handleChangeTabName} 
            /> 
        )
    })
    
    return(
        <div className="invoicesTabs">
            {/* {tab} */}
        </div>
  );
}

class Tabs extends Component{
    
    constructor(props){
        super(props)
        this.state={
            activeTab : 0
            // tabs: [
            //     {
            //         tabName: 'APPOINTMENTS',
            //         isTabActive: false
            //     },
            //     {
            //         tabName: 'BILLING',
            //         isTabActive: true
            //     }
            // ]
        }
    }

     /**
     * Change Tab active state on click on tab
     * @param {*} tabs 
     * @param {*} index 
     * @param {*} isTabActive 
     */
    changeActiveTab(tabs, index){
        // console.log(tabs, index);
        tabs.map((tabObj,tabIndex)=>{
            if(index===tabIndex){
                tabObj.isTabActive = true;
                tabs[tabIndex] = tabObj
            }else{
                tabObj.isTabActive = false;
                tabs[tabIndex] = tabObj
            }
        })
        return tabs
    }

    /**
     * Handle on click on tab
     * @param {*} index 
     * @param {*} tab 
     */
    handleInvoiceTab(index, tab, handleChangeTabName){
        console.log(index, tab);
        let tabs = this.state.tabs;
        tabs = this.changeActiveTab(tabs, index);
        handleChangeTabName(tab.tabName);
        // console.log(tabs);
        this.setState({
            tabs: tabs
        })
    }

    render(){
        let tabs = this.state.tabs;
        // console.log(tabs);
        return(
            <div></div>
            // <ShowTab 
            //     tabs={tabs}
            //     handleInvoiceTab={(index,tab)=>this.handleInvoiceTab(index, tab, this.props.handleChangeTabName)}
            // />
        )
    }
}

function AppointmentHistory(props){
    console.log(props.apptHistory);
    // debugger;
    const apptHistory = props.apptHistory ? props.apptHistory : [];
    const timeOffset = new Date().getTimezoneOffset(); 
    const apptDetailCard = apptHistory.map((appt)=>{
        console.log(new moment(new Date(appt.appointment_datetime).getTime()+timeOffset).format('HH : mm a'));
        // debugger;
        console.log(timeOffset*60*1000);
        console.log(new moment(Date.parse(new Date(appt.appointment_datetime))+timeOffset*60*1000).format('HH : mm a'));
        return (
            <div class="apptCard">
                <div class="apptTimings">
                    <div class="apptTiming">
                        <div class="apptTime lightFontColor">
                            {new moment(Date.parse(new Date(appt.appointment_datetime))+timeOffset*60*1000).format('HH : mm A')}
                        </div>
                        <div class="apptDate">
                            {new moment(appt.appointment_datetime).format('Do MMM')}
                            <div class="apptYear lightFontColor">{new moment(appt.appointment_datetime).format('YYYY')}</div>
                        </div>
                    </div>
                    <div class="circle">
                    </div>
                    <div class="line">
                    </div>
                </div>
                <div className="apptDetailCard">
                    <div className="apptDetails">
                        <div className="apptServices">
                            <div className="serviceHeading apptCardHeading">
                                Services <div className="servicesCount">+2</div>
                            </div>
                            <div className="servicesNames">
                                <div className="serviceName">Physician assistants</div>
                                <div className="serviceName">Nurse Practitioners</div>
                            </div>
                        </div>
                        <div class="apptPayment">
                            <div className="paymentHeading apptCardHeading">Payment</div>
                            <div className="payment">Rs. <span class="paymentNumber">500</span></div>
                        </div>
                        <div class="apptStatus">
                            <div className="statusHeading apptCardHeading">Status</div>
                            <div className="pp-status">{(appt.status)}</div>
                        </div>
                        <div className="apptButtons">
                            <span className="apptButton">Reschedule</span>
                            <span className="apptButton cancelButton">Cancel</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    });
    return(<>{apptDetailCard}   </>)
}

function Billing(){
    const invoices = new Array(10).fill(null).map(item=>{
        return <Invoice></Invoice>
    })
    return invoices;
} 

class PatientProfile extends Component {
    
    state = {
        patientDetails:null,
        selectedPatientId: null,
        appointmentHistory:null,
        invoiceList:null,
        selectedTab : 0,
        doctorId : 101161
    }

    componentDidMount() {
        // console.log(this.props);
        this.setInitialState();
        //When component first mount after creation
    }

    setInitialState(){
        this.setState({
            selectedPatientId: this.props.match.params.patientId
        },()=>{
            this.getAppointmentHistory(this.state.selectedPatientId, 101161);
        })
    }

    componentDidUpdate(){
        if(this.state.selectedPatientId !== this.props.match.params.patientId){
            this.setInitialState();
        }
    }

    switchTabHandler(type){
    console.log('TYPE IS', type);
    // debugger;
        // console.log('PATIENT DETAIL STORE', this.props.patientDetail);
        this.setState({'selectedTab':type}, ()=>{
            if(type===0 && !this.props.patientDetail.appointmentHistory){
                console.log('Appt History for this patient already exist.');
                this.getAppointmentHistory(this.state.selectedPatientId, this.state.doctorId);
            }

            if(type===1 && !this.props.patientDetail.invoiceHistory){
                console.log('Invoices for this patient already exist.');
                //Fetch invoices from server
                this.getInvoiceHistory(this.state.selectedPatientId, this.state.doctorId);
            }
        })
    }

    getAppointmentHistory(patId, docId){
        //Feth appointment history from the server for a specific patient
        const reqBody = {};
        axios.get('/getpatienthistory_test/'+docId+'/'+patId+'/'+0)
        .then(response=>{
            console.log(response.data[0]);
            if(response.data.length>0){
                this.setState({'appointmentHistory': response.data[0].pHistory});
            }
        })
    }
    
    getInvoiceHistory(patId, docId){
       console.log('PATIENT ID AND DOCTOR ID WHOSE INVOICE NEEDS TO BE FETCHED', patId, docId);
       const reqBody = {
        "invoices" : {"patient_id":patId, "doctor_id" : docId}
        }
        axios.post('/get_patient_invoice', reqBody)
            .then(response=>{
            console.log(response);
            // debugger;
            const invoices = response.data.invoices.map(invoice=>{
                invoice.discount = (invoice.discount) ? invoice.discount.discount_value : 0; 
                invoice.billedAmount = invoice.billing.reduce(function(total, bill){
                    console.log(bill);
                    return total + parseInt(bill.price)*bill.units;
                }, 0); 
                console.log(invoice.billedAmount);
                // debugger;
                invoice.totalAmount = invoice.billedAmount - ((invoice.discount) ? invoice.discount : 0);
                invoice.paidAmount = (invoice.payment!==undefined) ? invoice.payment.reduce(function(total, payment){
                    return total + parseInt(payment.amount);
                }, 0):0;
                invoice.balance = invoice.paidAmount-invoice.billedAmount;
                return invoice
            })
            console.log(invoices);
            // debugger;
            this.props.storeInvoices(invoices);
            this.setState({'invoices' : response.data.invoices});
        })
    }

    addInoviceHandler(patId) {
        this.props.history.push('/reception/addservices/'+patId);
    }

    editInvoiceHandler(invoice) {
        console.log(invoice);
        // const invoiceData = [];
        // for(let i=0;i<invoice.billing.length;i++){
        //     const bill = {
        //         serviceName : invoice.billing[i].service_name,
        //         category : invoice.billing[i].service_type+'s',  
        //         id : invoice.billing[i].service_id,
        //         price : parseInt(invoice.billing[i].price),
        //         quantity : invoice.billing[i].units,
        //         totalPrice : parseInt(invoice.billing[i].price)*invoice.billing[i].units,
        //         created_by : invoice.billing[i].created_by, 
        //         doctor_id : invoice.billing[i].doctor_id,
        //         createdtm : invoice.billing[i].createdtm 
        //     }
        //     invoiceData.push(bill);
        // }
        // console.log(invoiceData);
        // debugger;
        this.props.selectedInvoice(invoice);
        this.props.history.push('/reception/newinvoice/'+this.state.selectedPatientId);
        // debugger;
    }
     
    render() {
        return (
            <div className="patientProfile">
                <div className="pprLeft">
                    <div>
                        <div className="profileHead">
                            <div>
                                <img src={patIcon} />
                            </div>
                            <p className="namePat">Ritu Tiwari (25/F)</p>
                            <p className="numPat">+91- 8860144308</p>
                        </div>
                        <div>
                            <div>
                                <h4>Account Details</h4>
                                <table>
                                    <tr>
                                        <td className="key">Visit</td>
                                        <td className="value">7th</td>
                                    </tr>
                                    <tr>
                                        <td className="key">Last Visit</td>
                                        <td className="value">8th April, 2018</td>
                                    </tr>
                                    <tr>
                                        <td className="key">Amount Due</td>
                                        <td className="value">Rs 500</td>
                                    </tr>
                                    <tr>
                                        <td className="key">Average Time</td>
                                        <td className="value">15 min</td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <h4>Vital Details</h4>
                                <table>
                                    <tr>
                                        <td className="key">Visit</td>
                                        <td className="value">7th</td>
                                    </tr>
                                    <tr>
                                        <td className="key">Last Visit</td>
                                        <td className="value">8th April, 2018</td>
                                    </tr>
                                    <tr>
                                        <td className="key">Amount Due</td>
                                        <td className="value">Rs 500</td>
                                    </tr>
                                    <tr>
                                        <td className="key">Average Time</td>
                                        <td className="value">15 min</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pprRight"> 
                    <div className="pprTabs"> 
                        <div key="appointment" onClick={()=>this.switchTabHandler(0)} className={(this.state.selectedTab===0) ? 'activeTab': ''}>Appointments</div>
                        <div key="billing" onClick={()=>this.switchTabHandler(1)} className={(this.state.selectedTab===1) ? 'activeTab' : ''}>Billing</div>
                    </div> 
                    {(this.state.selectedTab!==0) ? <MainHeader selectedPatientId={this.state.selectedPatientId} addInovice={(patId)=>this.addInoviceHandler(patId)} /> : null} 
                        <div className="pprInvoiceContainer"> 
                            {(this.state.selectedTab===0) ? <AppointmentHistory apptHistory={this.state.appointmentHistory}></AppointmentHistory> : 
                            (<><Tabs 
                                handleChangeTabName={(tabName)=>this.handleChangeTabName(tabName)}
                            />
                            <TabDetails
                                tabName={this.state.tabName}
                                invoices={(this.state.invoices && this.state.invoices.length>0) ? this.state.invoices : []} 
                                editInvoice={(invoice)=>this.editInvoiceHandler(invoice)}
                            /></>)}
                        </div>
                </div>
                {/* <div className="ppLeft"> */}
                {/* <div class="patientProfileSideBar">
                        <div class="patientProfileCard">
                            <div class="patientProfileContainer">
                                <div class="patientPhoto">
                                    <img src={patIcon} alt="" />
                                </div>
                                <div class="patientDetails">
                                    <div class="basicDetails">
                                        <div class="pp-patientName">
                                            Ritu Tiwari
                                        </div>
                                        <div class="ageGender">
                                            (<span class="patientAge">25</span>/<span class="patientGender">F</span>)
                                        </div>
                                    </div>
                                    <div class="mobileNumber">
                                        <span class="phoneCode">+91</span>- <span class="pp-number">8860144308</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="patientOtherDetails">
                            <div class="patientAccountDetails">
                                <div class="detailsMainHeading">
                                    Account Details
                                </div>
                                <div class="accountDetails">
                                    <div class="timeVisited detailsRow">
                                        <span class="detailsHeading">Visit</span>
                                        <span class="detailsValue">7th</span>
                                    </div>
                                    <div class="lastVisit detailsRow">
                                        <span class="detailsHeading">Last Visit</span>
                                        <span class="detailsValue">8th Apr, 2018</span>
                                    </div>
                                    <div class="amountDue detailsRow">
                                        <span class="detailsHeading">Amount Due</span>
                                        <span class="detailsValue">Rs. <span class="dueAmountNumber">500</span> </span>
                                    </div>
                                    <div class="averageTime detailsRow">class="detailsValue">15min</span>
                                </div>
                                        {/* <span class="detailsHeading">Average Time</span>
                                        <span class="detailsValue">15min</span>
                                    </div> */}
                                {/* </div> */}
                            {/* </div>
                            <div class="patientVitalDetails">
                                <div class="detailsMainHeading">
                                    Vital Details
                                </div>
                                <div class="vitalDetails">
                                    <div class="detailsRow">
                                        <span class="detailsHeading">Height</span>
                                        <span class="detailsValue">186 <span class="unit">cm</span></span>
                                    </div>
                                    <div class="detailsRow">
                                        <span class="detailsHeading">Weight</span>
                                        <span class="detailsValue">86<span class="unit">kg</span> </span>
                                    </div>
                                    <div class="detailsRow">
                                        <span class="detailsHeading">Blood Group</span>
                                        <span class="detailsValue">B+</span>
                                    </div>
                                    <div class="detailsRow">
                                        <span class="detailsHeading">Temperature</span>
                                        <span class="detailsValue">103</span>
                                    </div>
                                    <div class="detailsRow">
                                        <span class="detailsHeading">BP</span>
                                        <span class="detailsValue">135/90</span>
                                    </div>
                                </div>       
                            </div>
                        </div> */}
                    {/* </div> */}
                {/* </div> */}
                {/* <div className="ppRight">
                    <div>
                    <div className="invoicesTabs">
                        <div className={'invoiceTab ' + ((this.state.selectedTab===0) ? 'active' : '')} onClick={()=>this.switchTabHandler(0)}>
                            APPOINTMENTS
                        </div>
                        <div className={'invoiceTab ' + ((this.state.selectedTab===1) ? 'active' : '')} onClick={()=>this.switchTabHandler(1)}>
                            BILLING
                        </div>
                    </div>
                    </div>
                    <div>
                        {(this.state.selectedTab===0) ? <AppointmentHistory></AppointmentHistory> : <Billing></Billing>}
                    </div>
                </div> */}
                <div>
                
                </div>
                
            </div>
        )
    }
  }

const mapStateToProps = (state) => {
    return {
        patientDetail : state.patientDetail,
        invoice:state.invoice
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        storeInvoices : (invoices) => dispatch({type:'STORE_INVOICES_LIST', prevInvoices: invoices}),
        selectedInvoice : (invoice) => dispatch({type:'EDIT_INVOICE', selectedInvoice: invoice}),
        // storeInvoiceData : (invoiceData) => dispatch({type:'STORE_INVOICE_FOR_EDIT', invoiceData: invoiceData})
        // storeInvoices : (invoices) => dispatch({type:'STORE_INVOICES_LIST', prevInvoices: invoices}),
        // upda : (invoices) => dispatch({type:'STORE_INVOICES_LIST', prevInvoices: invoices})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientProfile)