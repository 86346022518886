import React from 'react';
import './LoginForm.css';

function RenderMultiAddress({addresses, onClick}) {
    const  addressTemplate = addresses.map(add => {
        return (<div key={add.address_id} onClick={()=>onClick(add.address_id)}>{add.address_id}</div>)
    });
    return (
        <form>
            <div>Select Address</div>
            {addressTemplate}
        </form>
    );
}

// function RenderLoginForm() {
//  // Login Form Functional Component
// }

function LoginForm(props) {
    
    const invalidMsgView = (props.msg) ? (<div className="invalidMsg">{props.msg}</div>) : null;
    
    let form = null;
    if(props.addresses){
        form = <RenderMultiAddress addresses={props.addresses} onClick={(addId)=>props.selectAddress(addId)} />
    }else{
        form = (
            <form action="">
                {invalidMsgView}
                <div className="input-field-login">
                    <input type="text" name="username" id="username" maxLength="50" onChange={(e) => props.changeName(e.target.value)} required/>
                    <label>User name</label>
                </div>
                <div className="input-field-login">
                    <input type="password" name="password" maxLength="50" onChange={(e) => props.changePassword(e.target.value)} required/>
                    <label>Password</label>
                </div>
                {/* <div className="forgotPasswordLink"><a href="/">forgot password</a></div> */}
                <button type="submit" className="loginButton" onClick={(event) => props.submit(event)} disabled = {!props.isformvalid}>login</button>
            </form>
        )
    }
    return (
        <div className="loginModal">
            <div className="loginForm">
                <div className="loginTitle">LOGIN</div>
                {form}
            </div>
            <div className="loginImage"></div>
        </div>
    )
}

export default LoginForm;