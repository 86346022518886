import {combineReducers} from 'redux';

const initialState = {
    isAuthenticated : false,
    authRedirectPath :'/',
    userData : null,
    patientData : null,
    docColorCodes :null,
    selectedDoctor : null
}

const dateState = {
    date : new Date()
}

const paymentState = {
    payment : null
}

//User Reducer to contain doctors related fields

const userReducer = (state=initialState, action) => {
    switch(action.type){
        case 'AUTHENTICATE_USER' : {
            return {
                ...state,
                isAuthenticated:action.authenticated
            };
        }
        case 'STORE_DOCTORS_DATA' : {
            // console.log("Storing doctors data with action as", action)
            return {
                ...state,
                userData:action.userData,
            };
        }
        case 'STORE_PATIENT_DATA' : {
            // console.log("Storing doctors data with action as", action)
            return {
                ...state,
                patientData:action.value,
            };
        }
        case 'STORE_COLOR_CODES' : {
            // console.log(action.colorCodes);
            return {
                ...state,
                docColorCodes:action.colorCodes,
            };
        }
        case 'STORE_SELECTED_DOCTOR' : {
            return {
                ...state,
                selectedDoctor:action.doctor,
            };
        }
        default: 
            return state
    }
}

//Print Prescription Specific Data
const printReducer = (state={printingDetails:null},action) => {
    switch(action.type){
        case 'STORE_PRINTING_DETAILS' : {
            let docPrintList = state.docPrintList.filter((doctor)=>{
                return doctor.doctor_id===action.patDetails.doctor_id
            })[0];
            return {
                ...state,
                printingDetails:action.patDetails,
                printCoordinates: docPrintList.prescription_coordinates,
                apptDate: dateState
            }
        }

        case 'SET_DOC_PRINTLIST' : {
            return {
                ...state,
                docPrintList: action.docPrintList
            }
        }
        default:
            return state
    }
}

//DATE RELATED DATA
const dateReducer = (state=dateState, action) => {
    switch(action.type){
        case 'INCREMENT_DATE' : {
            return {
                ...state,
                date:new Date(dateState.getDate() + 1) 
            };
        }
        case 'DECREMENT_DATE' : {
            return {
                ...state,
                date:new Date(dateState.getDate() - 1) 
            };
        }
        case 'DATE_CHANGE' : {
            return {
                ...state,
                date:action.value
            }
        }
        default:
            return state
    }
    // return state
}

//APPOINTMENT RELATED DATA
const appointmentReducer = (state={appointments:null, rescheduleAppt:null, isConfirmed:false, apptType: null, isquikadd:false}, action) => {
    switch(action.type){
        case 'STORE_UPCOMING_APPTS' : {
            return {
                ...state,
                appointments:action.appointments 
            };
        }

        case 'APPT_TO_RESCHEDULE' : {
            return {
                ...state,
                rescheduleAppt:action.appointment,
                apptType: action.apptType
            };
        }

        case 'SET_APPT_CONFIRMATION' : {
            return {
                ...state,
                isConfirmed:action.isConfirmed
            };
        }
        case 'SET_QUICK_ADD' : {
            return {
                ...state,
                isQuickAdd:action.isQuickAdd
            };
        }
        
        default:{
            return state
        }
    }

}

//PAYMENT RELATED DATA
const paymentReducer = (state=paymentState, action) => {
    switch(action.type){
        case 'STORE_PREVIOS_PAYMENT' : {
            return {
                ...state,
                payment:action.payment 
            };
        }
        default:
            return state
    }
}

//PATIENT RELATED DATA
const patientDetailReducer = (state={selectedPatId:null, appointmentHistory:null, invoiceHistory:null}, action) => {
    switch(action.type){
        case 'SELECTED_PATIENT' : {
            return {
                ...state,
                selectedPatId:action.selectedPatient 
            };
        }
        default:
            return state
    }
}

const invoiceReducer = (state={invoiceData : [], invoices : [], selectedInvoiceId:null}, action) => {
    switch(action.type){ 
        case 'STORE_INVOICES_LIST' : {
            return {
                ...state,
                invoices : action.prevInvoices
            }
        }
        case 'STORE_INVOICE_DATA' : {
            return {
                ...state,
                invoiceData : action.invoiceData
            }
        }
        case 'SELECT_INVOICE_ID' : { 
            console.log(action);
            // debugger;
            return {
                ...state,
                selectedInvoiceId : action.selectedInvoiceId
            }
        }
        case 'EDIT_INVOICE' : {
            console.log(action);
            // debugger;
            return {
                ...state,
                selectedInvoice : action.selectedInvoice
            }
        }
        case 'STORE_INVOICE' : { 
            //Check if service exist in list or not
            //Filter and find index based on category and category_id
            const updatedState = {...state};
            let id = null;
            if(action.updatedService.id!==undefined || action.updatedService.category === 'services'){
                id = 'service_id';
            } else if(action.updatedService.category==='consultations') {
                id = 'consultation_id';
            } else {
                id = 'vaccination_id';
            }
            console.log(id);
            let index = -1;
            updatedState.invoiceData.forEach((service,i)=>{ 
                console.log(id, action.updatedService, action.updatedService[id], service, service[id]);
                if(action.updatedService[id] === service[id] && action.updatedService['serviceName'] === service['serviceName']){ 
                    index = i;
                }
            });
            console.log(index);
            // debugger;
            if(action.updatedService.quantity===0){
                //remove item from list
                updatedState.invoiceData.splice(index, 1);
            } else {
                 //Update total price
                
                // action.updatedService.totalPrice = action.updatedService.quantity*action.updatedService.price;
                //if not exit:- push it in list
                //if exist : 
                    // 1.find index : filter based on category and category_id
                    // 2.then update the list
                if(index>-1){
                    // update the data...(quantity for now)
                    updatedState.invoiceData[index].quantity = action.updatedService.quantity;
                    updatedState.invoiceData[index].totalPrice = action.updatedService.quantity*action.updatedService.price;
                    // console.log(updatedState);
                } else {
                    // push the data...
                    action.updatedService.totalPrice = action.updatedService.quantity*action.updatedService.price;
                    updatedState.invoiceData.push(action.updatedService); 
                }
            }
                
            return { 
                ...state, 
                invoiceData:updatedState.invoiceData 
            };
        } 
        default:
            return state 
    }
}

const allReducers = combineReducers({
    user:userReducer,
    date:dateReducer,
    appointmentData:appointmentReducer,
    payment : paymentReducer,
    printDetails: printReducer,
    patientDetail : patientDetailReducer,
    invoice : invoiceReducer
})


export default allReducers;