import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect}  from 'react-router-dom';
// import Dashboard from './containers/Dashboard/Dashboard'; 
import LoginPage from './containers/LoginPage/LoginPage';
import MainContent from './containers/MainContent/MainContent';
import PrescriptionModal from './components/PrescriptionModal/PrescriptionModal'
// import CreateAppointment from './containers/CreateAppointment/CreateAppointment';
import { connect } from 'react-redux';
// import './assets/js/ecdh_service';
// import genKeys from './assets/js/ecdh_service';

class App extends Component {
  render() {
    // window.scroll(0, 0);
    return (
      <Router>
        <div className="App">
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <Route path="/reception/:pageType" component={MainContent} />
          <Route path="/printingpage" component={PrescriptionModal}/>
          <Route path="/" component={() => {
            console.log(localStorage.getItem('loginId'));
            if(localStorage.getItem('loginId')){
              this.props.setAuthentucation(true);
              return (<Redirect to="/reception/dashboard/dayview" />)
            }              
            return (<Redirect to="/login" />)
          }} />
          <Route component={NoMatch} />
        </Switch>
        </div>
      </Router>    
    );
  }
  
}

function NoMatch() {
  return (
    <h1>Page Not Found</h1>
  )
}

const mapStateToProps = state => {
  return state;
}

const mapDispatchToProps = dispatch => {
  return {
    setAuthentucation:(isAuthentucated) => dispatch({type:'AUTHENTICATE_USER', value: isAuthentucated})
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(App)