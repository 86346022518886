import React, {Component} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Dashboard from '../Dashboard/Dashboard';
import { Route } from 'react-router-dom';
import CreateAppointment from '../../containers/CreateAppointment/CreateAppointment';
import { connect } from 'react-redux';
import { Container } from 'react-grid-system';
import Toast from 'react-bootstrap/Toast';
import EditPayment from '../../containers/EditPayment/EditPayment';
import PatientProfile from '../../containers/PatientProfile/PatientProfile';
import NewInvoiceService from '../../containers/NewInvoiceServices/NewInvoiceServices';
import NewInvoice from '../../containers/NewInvoice/NewInvoice';

// import '../../assets/js/sendersdk';
// import '../../assets/js/sender';
// import { timeout } from 'q';

class MainContent extends Component {
    componentDidMount() {
        this.backListener = this.props.history.listen(location => {
            if (location.action === "POP") {
                this.props.history.goBack();
            }
        });
        // console.log(appointmentData);
    }
    render() {
        // console.log('Appointment data', this.props.appointmentData.isConfirmed);
        let show = this.props.appointmentData.isConfirmed ? true : false;
        
        // If confirmation exist, disable confirmation after 3sec (toast display handling)
        if(show) {
            setTimeout(function(){
                this.props.setApptConfirmation(false);
            }.bind(this), 3000)
        }
        let userName = (this.props.user && this.props.user.userData) ? this.props.user.userData.docName:null;
        let userSpecialization= (this.props.user && this.props.user.userData) ? this.props.user.userData.specialization:null;
        // console.log('Toast issue log', this.props.appointmentData.isConfirmed && this.props.appointmentData.isConfirmed==='confirmed');
        return (
            <div>
                <Navbar parentProps={this.props} username={userName} specialization={userSpecialization} />
                <div className = "mar-80"></div>
                <Container className="container">
                    <Route path="/reception/dashboard/:calenderView" component={Dashboard} />
                    <Route path="/reception/createappointment/" component={CreateAppointment} />
                    <Route path="/reception/editpayment/" component={EditPayment} />
                    {/* <Route path="/reception/patientprofile/:patientId" component={PatientProfile} />
                    <Route path="/reception/addservices/:patientId" component={NewInvoiceService} />
                    <Route path="/reception/newinvoice/:patientId" component={NewInvoice} /> */}
                </Container>
                {(this.props.match.params.pageType==='dashboard') ? <div style={{position:"absolute",bottom:"50px", minWidth:"300px", textAlign:"center", margin:"0 50% 0 50%"}}>
                    <Toast show={show} animation>
                        <Toast.Body>{(this.props.appointmentData.isConfirmed && this.props.appointmentData.isConfirmed==='confirmed') ? 'Appointment Confirmed Successfully' : (this.props.appointmentData.isConfirmed && this.props.appointmentData.isConfirmed==='reschedule') ? 'Appt. Rescheduled Successfully' : (this.props.appointmentData.isConfirmed && this.props.appointmentData.isConfirmed==='edit') ? 'Appt. Updated Successfully' : (this.props.appointmentData.isConfirmed && this.props.appointmentData.isConfirmed==='error') ? 'Some error occoured': ''}</Toast.Body>
                    </Toast>
                </div> : null}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        setApptConfirmation : (isConfirmed) => dispatch({type:'SET_APPT_CONFIRMATION', isConfirmed: isConfirmed})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContent)