import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {createStore} from "redux";
import './index.css';
import App from './App';
import allReducer from './reducer';
import * as serviceWorker from './serviceWorker';

console.log = function() {};
const store = createStore(allReducer);
store.subscribe(()=>{
    console.log('Subscribed');
})  

// console.log('Get Store State', store.getState());
ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

serviceWorker.unregister();
