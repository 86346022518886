import React from 'react';
import paymentStyle from './Payment.module.css'
import cashIcon from '../../assets/images/icons/cash_icon.svg'
import cashBlueIcon from '../../assets/images/icons/cash_blue.svg'
import cardIcon from '../../assets/images/icons/card.svg'
import cardBlueIcon from '../../assets/images/icons/card_blue.svg'
import walletIcon from '../../assets/images/icons/wallet.svg'
import walletBlueIcon from '../../assets/images/icons/wallet_blue.svg'

/**
 * This method is rendering left service item in payment card
 * @param {*} param0 
 */

function ServiceItem({medicalService}){
    if(!medicalService || (medicalService && medicalService.amount===0)){
        return(
            <p className={paymentStyle.serviceItem}>
                <span className={paymentStyle.serviceName}>No services</span>
            </p>
        )
    }
    return(
        <>
            <p className={paymentStyle.serviceItem}>
                <span className={paymentStyle.rupees}>Rs</span>
                <span className={paymentStyle.payment}>{medicalService.amount}</span>
                <span className={paymentStyle.hyphen}>-</span>
                <span className={paymentStyle.serviceName}>{medicalService.name}</span>
            </p>
        </>
    )
}

function PaymentMode({paymentMode, isActive,changePaymentMode}){
    // console.log(paymentMode,isActive);
    return(
        <div className={paymentStyle.paymentMode} onClick={()=>changePaymentMode(paymentMode.mode)}>
            <div className={(isActive)? `${paymentStyle.modeIcon} ${paymentStyle.cashModeIcon}` : paymentStyle.modeIcon}>
                <img src={(isActive)?paymentMode.activeIcon:paymentMode.icon} />
            </div>
            <div className={`${paymentStyle.modeLabel}`}>
                {paymentMode.mode}
            </div>
        </div>
    )
}

/**
 * This method is rendering main payment card
 * @param {*} param0
 */
function PaymentCard({addedAmount, payableAmount, paidAmount, onPaidAmount, onAddQuickAmount, medicalService, addcustomamount, onAddAmount, isAddAmount, modeActive,changePaymentMode}){
    console.log(paidAmount, payableAmount);
    // debugger;
    let paymentModes = [{
        mode: 'cash',
        icon: cashIcon,
        activeIcon: cashBlueIcon
    }, {
        mode: 'card',
        icon: cardIcon,
        activeIcon: cardBlueIcon
    }, {
        mode: 'eWallet',
        icon: walletIcon,
        activeIcon: walletBlueIcon
    }]

    let paymentMode = paymentModes.map((paymentMode,index)=>{ 
    console.log(paymentMode) 
    return <PaymentMode  
                key={index}  
                paymentMode={paymentMode} 
                isActive={modeActive===paymentMode.mode} 
                changePaymentMode={changePaymentMode} 
            />
            
    })
    // console.log(medicalService);
    // console.log('PAYABLE AND PAID AMOUNT', paidAmount, payableAmount);
    return(
        <div className={paymentStyle.paymentCard}>
                <div className={`${paymentStyle.paymentHeader}` }>
                    <div className={paymentStyle.quickAdd}>Quick Add</div>
                    <div className={paymentStyle.quickBtnGroup}>
                        <span className={paymentStyle.quickAddBtn} onClick={()=>onAddQuickAmount(100)}>
                            +<span>100</span>
                        </span>
                        <span className={paymentStyle.quickAddBtn} onClick={()=>onAddQuickAmount(500)}>
                            +<span>500</span>
                        </span>
                        <span className={paymentStyle.quickAddBtn} onClick={()=>onAddQuickAmount(1000)}>
                            +<span>1000</span>
                        </span>
                        {/* <span className={paymentStyle.quickAddBtn} onClick={()=>addcustomamount()}>
                            +<span>CA</span>
                        </span> */}
                    </div>
                </div>
                <div className={`${paymentStyle.paymentBody} `}>
                    <div className={paymentStyle.services}>
                        <p className={paymentStyle.servicesTitle}>Services :</p>
                        <ServiceItem 
                            medicalService={medicalService}
                        />
                    </div>
                    <div className={paymentStyle.amounts}>
                        <div className={paymentStyle.payableAmountSection}>
                            <span className={paymentStyle.payableAmountLabel}>Payable</span>
                            <div className={paymentStyle.payableAmount}>
                                <span className={paymentStyle.rupeeIcon}>&#x20B9;</span>
                                <div className={paymentStyle.payableAmountFigure}>
                                    {payableAmount}
                                </div>
                            </div>
                        </div>
                        <div className={paymentStyle.paidAmountSection}>
                            <span className={paymentStyle.paidAmountLabel}>Paid Amount</span>
                            <span className={paymentStyle.paidAmount}>
                                <input type="text" maxLength="7" value={paidAmount} onChange={(event)=>onPaidAmount(event.target.value)} className={paymentStyle.paidAmountInput} placeholder="&#x20B9;" disabled={isAddAmount} />
                            </span>
                        </div>
                        {(isAddAmount) ? <div className={paymentStyle.paidAmountSection}>
                            <span className={paymentStyle.paidAmountLabel}>Add Amount</span>
                            <span className={paymentStyle.paidAmount}> 
                                <input type="text" maxLength="7" onChange={(event)=>onAddAmount(parseInt(event.target.value))} value={addedAmount} className={paymentStyle.paidAmountInput} placeholder="&#x20B9;"/>
                            </span>
                        </div> : null}
                        <div className={paymentStyle.paymentModes}>
                           {paymentMode}
                        </div>
                        {/* <div className={paymentStyle.updateButtonContainer}>
                            <span className={paymentStyle.updateButton}>Update Payment</span>
                        </div> */}
                    </div>
                </div>
        </div>
    )
}

export default PaymentCard
