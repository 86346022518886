import React from 'react';
import './Navbar.css';
import { Dropdown } from 'react-bootstrap';

import logo from '../../assets/images/logo.svg';
// import bell from '../../assets/images/icons/bell.svg';
// import settings from '../../assets/images/icons/settings.svg';
import docimage from '../../assets/images/receptionimg.png';
import axios from '../../axios-order';
import { connect } from 'react-redux'; 

class CustomToggle extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.handleClick = this.handleClick.bind(this);
    }
  
    handleClick(e) {
      e.preventDefault();
      this.props.onClick(e);
    }

    render() {
      return (
        <div className = "dropMenuHeader">
            <div className="item1-drop" onClick={this.handleClick}>{this.props.children}</div>
        </div>
      );
    }
  }


class CustomMenu extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.handleChange = this.handleChange.bind(this);
      this.state = { value: '' };
    }
  
    handleChange(e) {
      this.setState({ value: e.target.value.toLowerCase().trim() });
    }

    changeHandler(event){
        // console.log(event);
    }
  
    render() {
      const {
        children,
        style,
        className,
        'aria-labelledby': labeledBy,
      } = this.props;
      // console.log(this.props.type);
      const { value } = this.state;
  
      return (
        <div style={style} className={className} aria-labelledby={labeledBy}>
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              child =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      )
    }
  }

class Navbar extends React.Component {
  state = {
    recommendedPats : null,
    showSearch  :false
  }

  selectPatHandler(patId, docId) {
    console.log(patId, docId);
    this.props.selectPatId(patId);
    console.log(this.props.parentProps);
    this.setState({recommendedPats:null});
    this.props.parentProps.history.push('/reception/patientprofile/'+patId);
    // debugger;
    // const reqBody = {
    //   "invoices" : {"patient_id":patId, "doctor_id" : docId}
    // }
    // axios.post('/get_patient_invoice', reqBody)
    // .then(response=>{
    //   console.log(response.data);
    // })
    //const
  }
  
  searchPatientHandler(value, docId){
    if(value.length<10){
      this.setState({recommendedPats:null});
      return null
    }
    console.log(value, docId);
    const reqBody = {
      doctor_id: 101161, //TODO: Static IP
      search_type: 1,
      search_value: value
    }

    axios.post('/getPatientSearch_reception_web', reqBody)
    .then((response)=>{
      this.setState({recommendedPats : response.data.patient_search});
    }, (errorResponse) => {
      //Error instructions goes here...
    })
  }

  logout() {
    localStorage.removeItem('loginId');
    localStorage.removeItem('addId');
    localStorage.removeItem('print_prescription');
    localStorage.removeItem('is_master');
    localStorage.removeItem('scan_visibility');
    localStorage.removeItem('payment_visibility');
    window.location.href = '/';
  }
  render() {
    const recommendedPatsTemplate = (this.state.recommendedPats) ? this.state.recommendedPats.map(pat=>{
      console.log(pat);
      return (
        <div key={pat.patient_id} onClick={()=>this.selectPatHandler(pat.patient_id, 101161)}>
          {/* {() ? <img src={pat.patient_image} alt="/profilepic" /> : null} */}
          <div className="nav-drop-image">
            <img src={pat.patient_image} />
          </div>
          <div>
          <div className="patName">
            {pat.patient_fullnm}
          </div>
          <div className="patNumber">
            {pat.patient_phone_number}
          </div>
          <div className="lastVisit">
            {pat.patient_fullnm}
          </div>
          </div>
        </div>
      )
    }) : null;
      
    // console.log(recommendedPats);
    // debugger;
    // console.log('Props for JNavbar', this.props); 
    return (
      <section className="header">
          <div className="headerContainer">        
              <div className="logo container-items">
                  <img src={logo} alt="" />
              </div>
              {/* <div className="searchNavComtainer">
                <div>
                  <input className="container-items searchInput" type="text" maxLength="10" placeholder="Search Patients" onChange={(event)=>this.searchPatientHandler(event.target.value)} onBlur={()=>this.setState({showSearch:false || 1})} onFocus={()=>this.setState({showSearch:true})} />
                </div>
                {(this.state.recommendedPats && this.state.showSearch) ? (<div className="navRecommendedPatBlock">
                  {recommendedPatsTemplate}
                </div> ): null}
              </div> */}
              
              {/* <a href="" className="bell-icon container-items">
                  <img src={bell} alt="" />
                  <span className="notification-count">5</span>
              </a>
              <a href="" className="settings-icon container-items">
                  <img src={settings} alt="" />
              </a>  */}
              <div className="nav-dashboard-item">
                <div className="castIcon">
                <google-cast-launcher id="castbutton"></google-cast-launcher>
                </div>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        <div className="logout-image container-items">
                            <img src={docimage} alt="" />
                        </div>
                        <div className="logout-content container-items">
                          <div className="user-name">{/*this.props.username*/}{localStorage.getItem('userName')}</div>
                          <div className="specialization">{/*this.props.specialization*/} {localStorage.getItem('userSpecialization')}<div className="downIcon"></div></div>
                        </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomMenu}>
                            <Dropdown.Item onClick={()=>this.logout()}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                
              </div>      
              
          </div>
      </section>
      )
    }
  }
  const mapStatesToProps = state => {
    return state
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      selectPatId : (patId) => dispatch({type:'SELECTED_PATIENT', selectedPatient:patId})
    }
  }
  export default connect(mapStatesToProps, mapDispatchToProps)(Navbar)