import React, {Component} from 'react';
import PaymentCard from '../../components/Payment/Payment';
import style from './EditPayment.module.css';
import {localtimeiso} from '../../assets/js/date_conversions';
import {Button} from 'react-bootstrap';
import {connect} from 'react-redux'
import axios from '../../axios-order';
import moment from 'moment';

class EditPayment extends Component{
    state = {
        defaultPayable:0,
        medicalService:null,
        initialAmount : 0,
        paidAmount:0,
        payableAmount:0,
        billing:[],
        payment:[],
        isAmountValid : false,
        addedAmount:0,
        modeActive: 'cash'
    }

    componentDidMount(){
        // debugger;
        /*Get payment and billing*/
        window.scrollTo(0, 0);
        const pay = this.props.payment.payment;
        console.log('VARIABLE PAY', pay);
        if(!pay){
            this.props.history.push('/reception/dashboard/dayview')
            return null;
        } 
        const billing = (pay) ? pay.billing : [];
        const payment = (pay) ? pay.payment : [];
        console.log(payment);
        const medicalService = billing.filter(item=>{
            return item.service_type==="default";
        });
        console.log('Medical Service Object', medicalService);
        const paidValue = (medicalService.length>0) ? parseInt(medicalService[0].price) : 0;
        console.log('PAY OBJECT', pay);
        //State set
        this.setState({
            payableAmount:parseInt(pay.totalAmount), 
            paidAmount:parseInt(pay.totalAmount), 
            defaultPayableAmount:parseInt(pay.totalAmount), 
            billing : medicalService, 
            payment : payment[0], 
            medicalService : {
                name : 'Medical Service', 
                amount : parseInt(paidValue)
            }, 
            initialAmount:parseInt(paidValue),
        })
    }

    findapayable(paid, payable) {
        return (paid>payable) ? paid : payable; 
    }

    /**
     * This method will handle the amount paid by the patient
     * @param {*} paidAmount 
     */
    handlePaidAmount(paidAmount, due){
        // debugger;
        console.log('Paid Amount is ',paidAmount);
        const paidAmt = (paidAmount) ? (paidAmount.charAt(0).search(/^[0-9]/)>-1) ? parseInt(paidAmount) : 0 : 0;
        const payableAmount = paidAmt;//this.findapayable(paidAmt, due);
        const serviceAmount = paidAmt;//this.findserviceamount(paidAmt, due);
        const updatedDue = (serviceAmount>0) ? 0 : due-paidAmount;
        this.setState({
            paidAmount : paidAmt,
            payableAmount : payableAmount,
            medicalService : {name : "medical service", amount : serviceAmount}
        })
    }

    editPaymentHandler(modeActive){
        modeActive = (modeActive)?modeActive:this.state.modeActive;
        console.log(modeActive);
        // debugger;
        // if(this.state.paidAmount<=)
        const pay = this.props.payment.payment;
        let payment = pay.payment;
        let billing = pay.billing;
        const medBilling = pay.billing.filter(item=>{
            return item.service_type==="default"
        })
        
        if(medBilling.length>0){
            billing = pay.billing.map((item)=>{
                if(item.service_type==="default"){
                    item.price = item.price + this.state.addedAmount;
                }
                return item;
            })
        } else {
            billing.push({
                created_by : "reception",
                price:this.state.addedAmount.toString(),
                service_name : "medical service",
                updated_by:"reception",
                units:1,
                updatedtm: localtimeiso(new Date()),
                service_id:"22012",
                createdtm:localtimeiso(new Date()),
                tax :"0.0",
                service_type:"default"  
            })
        }        

        if(this.state.addedAmount){
            payment.push({
                amount:this.state.addedAmount,
                payment_mode:modeActive,
                is_service : true,
                updatedtm :localtimeiso(new Date()),
                collected_by :"reception",
                is_refund:"false",
                collectdtm:localtimeiso(new Date()) 
            })
        }

        const reqData = {
            "appointment_id": this.props.payment.payment.apptId,
            "appt_doctor_id" : this.props.payment.payment.docId,
            "appt_patient_id": this.props.payment.payment.patId,
            "payment" : payment,
            "billing" : billing,
            "fees_paid" : parseInt(pay.feePaid) + parseInt(this.state.addedAmount),
            "total_amount" : parseInt(pay.totalAmount) + parseInt(this.state.addedAmount),
            "updatedtm": new moment().format("YYYY-MM-DD HH:mm:ss")
        }
        
        console.log(reqData);
        axios.post('/postnewappointment_reception_web', reqData)
        .then(response=>{
            alert("Payment Updated Successfully");
            // this.props.setRoute({
            //     route : "home",
            //     category : 1
            // })
            this.props.history.push('/reception/dashboard/dayview');
        },  (error)=>{
            //Error Instructions goes here
            alert('Unable to update payment. Kindly reload the page.');
        })
    }

    changePaymentMode(paymentMode){
        console.log(paymentMode);
        this.setState({modeActive: paymentMode})
    }

    render(){
        // debugger;
        console.log('PAYABLE AMOUNT', this.state);
        return(
            <div className={style.EditPayment}>
                <PaymentCard
                    isAddAmount={true} 
                    payableAmount={this.state.payableAmount}
                    paidAmount={this.state.paidAmount}
                    addedAmount={this.state.addedAmount} 
                    onPaidAmount={(value)=>this.handlePaidAmount(value, this.state.defaultPayableAmount)}
                    onAddQuickAmount = {(val)=>{
                            let amount = (isNaN(val)) ? 0 : parseInt(val);
                            amount = parseInt(this.state.addedAmount) + amount;
                            const updatedAmount = parseInt(this.props.payment.payment.totalAmount)+amount;
                            this.setState({addedAmount:amount, payableAmount:updatedAmount, paidAmount:updatedAmount, medicalService : {name : 'medical service', amount : this.state.initialAmount+amount}})
                        // const paidAmount = this.state.paidAmount + parseInt(value);
                        // this.handlePaidAmount(paidAmount.toString(), this.state.defaultPayableAmount);
                    }}
                    onAddAmount={(val)=>{
                        console.log('On Change Val', val);
                        const amount = (isNaN(val)) ? 0 : val;
                        const updatedAmount = parseInt(this.props.payment.payment.totalAmount)+parseInt(amount);
                        console.log('AMOUNT AND UPDATED AMOUNT', amount, updatedAmount);
                        console.log('INITIAL AMOUNT', this.state.initialAmount);
                        //Set state
                        this.setState({addedAmount:amount, payableAmount:updatedAmount, paidAmount:updatedAmount, medicalService : {name : 'medical service', amount : parseInt(this.state.initialAmount)+parseInt(amount)}})}
                    }
                    medicalService = {this.state.medicalService}
                    modeActive = {this.state.modeActive}
                    changePaymentMode = {(paymentMode)=>this.changePaymentMode(paymentMode)}
                    />
                    
                <div className={style.editPaymentBtnGroup}>
                {/* <Button className={style.editPaymentBtnReset} onClick={()=>this.componentDidMount()}>Reset</Button> */}
                    <Button className={style.editPaymentBtnEdit} onClick={()=>this.editPaymentHandler(this.state.modeActive)} disabled={!this.state.addedAmount}>Done</Button>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state)=>{
   return {
       payment : state.payment,
       routes : state.routes
   }
}

export default connect(mapStateToProps, null)(EditPayment)